import { connect } from 'react-redux';

import integrationsActionsCreators from '_store/_actions/integrations';

import ProviderModal from './ProviderModal';

const mapStateToProps = (state) => ({
  isLoading: state.fiscal.isLoading,
});

const mapDispatchToProps = {
  onCreateIntegration: integrationsActionsCreators.createIntegration,
  onFetchIntegration: integrationsActionsCreators.getIntegrationByType,
  onUpdateIntegration: integrationsActionsCreators.updateIntegration,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProviderModal);
