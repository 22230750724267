import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';

import { useMediaQuery } from 'helpers';

import {
  Card,
  CardBody,
  FormTextField,
  Button,
  Tabs,
  PageHeader,
} from '_components/_core';
import { UpdateEmail } from '_components/_shared';

import { Container } from 'react-bootstrap';
import { AvatarUpload } from './components';
import ProfileSchema from './utilities';

const Profile = ({
  isLoading,
  user,
  onUpdateUser,
  onFetchUser,
  onAvatarUpload,
  onAvatarDelete,
  isCompact,
}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [userFetched, setUserFetched] = useState(false);

  const { isMobile } = useMediaQuery();

  const handleUpdateProfile = values => {
    onUpdateUser(values);
  };

  useEffect(() => {
    if (!userFetched) {
      onFetchUser();
      setUserFetched(true);
    }
  }, [onFetchUser, userFetched]);

  const getTabs = useCallback(
    handleSubmit => [
      {
        id: 1,
        title: 'Dados de acesso',
        content: (
          <>
            <Form>
              <Form.Row>
                <Form.Group as={Col} lg="6">
                  <Form.Label>
                    {user.document_type === 'PF' ? 'Nome' : 'Razão Social'}
                  </Form.Label>
                  <FormTextField
                    name="first_name"
                    placeholder={user.document_type === 'PF' ? 'Nome' : 'Razão Social'}
                  />
                </Form.Group>
                <Form.Group as={Col} lg="6">
                  <Form.Label>
                    {user.document_type === 'PF' ? 'Sobrenome' : 'Nome Fantasia'}
                  </Form.Label>
                  <FormTextField
                    name="last_name"
                    placeholder={
                      user.document_type === 'PF' ? 'Sobrenome' : 'Nome Fantasia'
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} lg="6">
                  <Form.Label>E-mail</Form.Label>
                  <FormTextField name="email" placeholder="E-mail" disabled />
                  <UpdateEmail />
                </Form.Group>
                <Form.Group as={Col} lg="6">
                  <Form.Label>Senha (deixe em branco para não alterar)</Form.Label>
                  <FormTextField name="password" placeholder="Senha" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="3">
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    disabled={isLoading}
                  >
                    Salvar
                  </Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </>
        ),
      },
    ],
    [isLoading, user],
  );

  return (
    <Container
      fluid
      className={classNames({
        'content-wrapper': !isCompact,
      })}
    >
      {!isCompact && <PageHeader title="Meus dados" variant="small" />}
      <Formik
        initialValues={{
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        }}
        onSubmit={handleUpdateProfile}
        enableReinitialize
        validationSchema={ProfileSchema}
      >
        {({ handleSubmit }) => (
          <Row>
            <Col>
              <Card noBorders>
                <CardBody className="mt-3">
                  <Form>
                    <Form.Group as={Col} lg="12">
                      <AvatarUpload
                        user={user}
                        user_id={user.id}
                        avatar_url={user.avatar_url}
                        onAvatarUpload={onAvatarUpload}
                        onAvatarDelete={onAvatarDelete}
                        onFetchUser={onFetchUser}
                      />
                    </Form.Group>
                  </Form>
                  <Tabs
                    className={isMobile ? 'd-flex justify-content-center' : ''}
                    tabs={getTabs(handleSubmit)}
                    variant="secondary"
                    onTabChange={tab => setActiveTab(tab)}
                    activeTab={activeTab}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Formik>
    </Container>
  );
};

export default Profile;

Profile.defaultProps = {
  user: {},
  isCompact: false,
};

Profile.propTypes = {
  onUpdateUser: PropTypes.func,
  onFetchUser: PropTypes.func,
  onAvatarUpload: PropTypes.func,
  onAvatarDelete: PropTypes.func,
  user: PropTypes.object,
  isLoading: PropTypes.bool,
  isCompact: PropTypes.bool,
};
