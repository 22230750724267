/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { LuCheckCircle2, LuXCircle } from 'react-icons/lu';

import FORMATTERS from 'helpers/formatters';
import { CustomCardBody } from '../../../../../styles';

import {
  Plan as Plan2,
  PlanDescription,
  PlanFeatures,
  PlanName,
  PlanNameContainer,
  PlanPrice,
  StyledButton,
} from '../styles';

function Plan({
  isActive,
  plan,
  onSelectPlan,
  onChoosePlan,
}) {
  const {
    cashflow_enabled,
    billing_enabled,
    nfse_enabled,
    max_users,
    max_storage_size_megabytes,
    max_transactions_per_month,
  } = plan;

  const features = [];

  features.push({
    description: (
      <span>
        Fluxo de Caixa
      </span>
    ),
    enabled: cashflow_enabled,
  });

  features.push({
    description: (
      <span>
        Emissão de Boletos
      </span>
    ),
    enabled: billing_enabled,
  });

  features.push({
    description: 'Emissão de NFS-e',
    enabled: nfse_enabled,
  });

  features.push({
    description: (
      <span>
        Até {max_transactions_per_month} transações (por mês)
      </span>
    ),
    enabled: true,
  });
  features.push({
    description: `Até ${max_users} ${pluralize('Usuário', max_users)}`,
    enabled: true,
  });
  // features.push({
  //   description: `${max_custom_receipts} Recibos Personalizados`,
  //   enabled: true,
  // });
  features.push({
    description: `Até ${max_storage_size_megabytes / 1000} GB de armazenamento`,
    enabled: true,
  });

  if (!plan) {
    return null;
  }

  const FeatureItem = ({ feature, enabled }) => (
    <li>
      <div className="d-flex align-items-center">
        {enabled && <LuCheckCircle2 size="1.1rem" className="text-success mr-3" />}
        {!enabled && <LuXCircle size="1.1rem" className="text-danger mr-3" />}
        <span>{feature}</span>
      </div>
    </li>
  );

  return (
    <Plan2
      key={plan.id}
      active={isActive}
      onClick={() => onSelectPlan(plan.id)}
    >
      <CustomCardBody>
        <PlanNameContainer>
          <div>
            <PlanName>
              {plan.name}
            </PlanName>
            <PlanDescription>
              {plan.description}
            </PlanDescription>
          </div>
        </PlanNameContainer>
        <div className="d-flex align-items-center pt-2">
          <PlanPrice>
            {FORMATTERS.NUMBER(plan.price)}
          </PlanPrice>
          <PlanDescription className="ml-1">
            {plan.cycle_months === 1 && 'por mês'}
            {plan.cycle_months === 6 && 'a cada 6 meses'}
            {plan.cycle_months === 12 && 'a cada 12 meses'}
          </PlanDescription>
        </div>
        <StyledButton
          variant="inverse-dark"
          className="mt-3"
          isActive={isActive}
          onClick={() => onChoosePlan(plan.id)}
        >
          Selecionar plano
        </StyledButton>
        <hr />
        <PlanFeatures className="mt-3">
          {features.map((feature, index) => (
            <FeatureItem
              key={index}
              feature={feature.description}
              enabled={feature.enabled}
            />
          ))}
        </PlanFeatures>
      </CustomCardBody>
    </Plan2>
  );
}

Plan.propTypes = {
  isActive: PropTypes.bool,
  plan: PropTypes.object.isRequired,
  onSelectPlan: PropTypes.func.isRequired,
  onChoosePlan: PropTypes.func.isRequired,
};

export default Plan;
