import { connect } from 'react-redux';

import apiKeyActionCreators from '_store/_actions/apiKeys';

import ApiKeysModal from './ApiKeysModal';

const mapStateToProps = (state) => ({
  isLoading: state.apiKeys.isLoading,
});

const mapDispatchToProps = {
  onCreateKey: apiKeyActionCreators.createKey,
  onFetchKeys: apiKeyActionCreators.getAllKeys,
  onUpdateKey: apiKeyActionCreators.updateKey,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeysModal);
