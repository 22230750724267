import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';

import {
  Button,
  FormSelectField,
  FormTextField,
} from '_components/_core';

import {
  StyledModal,
  ModalFooter,
} from './styles';

const ApiKeysModal = ({
  isVisible,
  onModalToggle,
  provider,
  onCreateIntegration,
  onFetchIntegration,
  isLoading,
  onUpdateIntegration,
}) => {
  const handleCreateUpdateIntegration = useCallback((values, { resetForm }) => {
    if (provider) {
      onUpdateIntegration(provider.provider, values, () => {
        onModalToggle();
        onFetchIntegration();
        resetForm();
      });
    } else {
      onCreateIntegration(values, () => {
        onModalToggle();
        onFetchIntegration();
        resetForm();
      });
    }
  }, [onCreateIntegration, onFetchIntegration, onModalToggle, onUpdateIntegration, provider]);

  const renderModalFooter = useCallback((handleSubmit, isValid) => (
    <ModalFooter>
      <Button variant="secondary" onClick={onModalToggle}>
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="dark"
        onClick={handleSubmit}
        isLoading={isLoading}
        disabled={!isValid || isLoading}
      >
        Salvar
      </Button>
    </ModalFooter>
  ), [onModalToggle, isLoading]);

  if (!isVisible) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        provider: provider ? provider.provider : null,
        staging_api_key: provider ? provider.staging_api_key : null,
        production_api_key: provider ? provider.production_api_key : null,
      }}
      onSubmit={handleCreateUpdateIntegration}
      enableReinitialize
    >
      {({ handleSubmit, values, isValid }) => (
        <>
          <StyledModal
            title={provider ? 'Editar Integração' : 'Criar Integração'}
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(handleSubmit, isValid)}
          >
            <Form>
              <Form.Row>
                <Form.Group as={Col} lg="12">
                  <Form.Label>
                    Provedor
                  </Form.Label>
                  <FormSelectField
                    name="provider"
                    placeholder="Selecione um provedor"
                    options={[
                      { value: 'ASAAS', label: 'ASAAS' },
                    ]}
                  />
                </Form.Group>
              </Form.Row>
              {values.provider === 'ASAAS' && (
                <>
                  <Form.Row>
                    <Form.Group as={Col} lg="12">
                      <Form.Label>
                        API Key Sandbox / Testes
                      </Form.Label>
                      <FormTextField
                        name="staging_api_key"
                        placeholder="Chave de API - Sandbox"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} lg="12">
                      <Form.Label>
                        <span className="text-danger">
                          API Key de Produção
                        </span>
                      </Form.Label>
                      <FormTextField
                        name="production_api_key"
                        placeholder="Chave de API - PRODUÇÃO"
                      />
                    </Form.Group>
                  </Form.Row>
                </>
              )}
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default ApiKeysModal;

ApiKeysModal.defaultProps = {
  isVisible: false,
};

ApiKeysModal.propTypes = {
  isVisible: PropTypes.bool,
  onModalToggle: PropTypes.func.isRequired,
  provider: PropTypes.object,
  onCreateIntegration: PropTypes.func.isRequired,
  onFetchIntegration: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onUpdateIntegration: PropTypes.func.isRequired,
};
