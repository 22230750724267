import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  // Separator,
  // Submenu,
  useContextMenu,
} from 'react-contexify';
import { IoAddOutline, IoChevronDown, IoChevronForward } from 'react-icons/io5';
import {
  LuSettings,
  LuUsers,
  LuCreditCard,
  // LuTags,
  // LuFolderTree,
  // LuBookmark,
  // LuLandmark,
  // LuComponent,
} from 'react-icons/lu';
import isEmpty from 'lodash/isEmpty';

import { Button, ContextMenu } from '_components/_core';
import { LoadingIcon, SubscriptionStatusTag } from '_components/_shared';

import pluralize from 'pluralize';
import {
  Footer,
  Trigger,
  ActiveCompany,
  MenuItem,
  CompanyAvatar,
  SearchMenuItem,
  CompanyListItem,
  AddCompanyButton,
  Dot,
  SubscriptionInfo,
  UserCount,
  PlanName,
} from './styles';

function CompanySwitcher({
  isLoading,
  activeCompany,
  companiesList,
  onFetchActiveCompany,
  onFetchMyCompanies,
  onActivateCompany,
  onFetchCompanies,
}) {
  const history = useHistory();

  const triggerRef = useRef(null);

  const [isSwitching, setIsSwitching] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRedirect = useCallback(
    path => {
      history.push(path);
    },
    [history],
  );

  useEffect(() => {
    onFetchActiveCompany();
    onFetchCompanies();
  }, [onFetchActiveCompany, onFetchCompanies]);

  const { trading_name } = activeCompany || {};

  const MENU_ID = 'company-switcher';

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  const handleMenuOpen = useCallback(
    e => {
      const buttonPosition = triggerRef.current.getBoundingClientRect();

      show(e, {
        position: {
          x: buttonPosition.left,
          y: buttonPosition.bottom + 5,
        },
      });
    },
    [show],
  );

  const handleEditCompany = useCallback(() => {
    history.push('/configuracoes/visao-geral');
  }, [history]);

  const renderAvatar = useCallback((company, size = 'normal') => {
    if (!company) {
      return null;
    }

    if (company.company_avatar_url) {
      return <CompanyAvatar src={company.company_avatar_url} size={size} />;
    }

    return (
      <div className="avatar">{`${company.trading_name.charAt(0).toUpperCase()}`}</div>
    );
  }, []);

  const handleActivateCompany = useCallback(
    company => {
      setIsSwitching(true);

      onActivateCompany(company, () => {
        setIsSwitching(false);
      });
    },
    [onActivateCompany],
  );

  const currentCompany = useMemo(() => {
    if (!activeCompany) {
      return null;
    }

    return companiesList.find(c => c.id === activeCompany.id);
  }, [companiesList, activeCompany]);

  const otherCompanies = useMemo(() => {
    if (!activeCompany) {
      return [];
    }

    return companiesList.filter(c => c.id !== activeCompany?.id);
  }, [companiesList, activeCompany]);

  const relatedActiveCompany = useMemo(() => {
    if (!activeCompany) {
      return null;
    }

    return companiesList.find(c => c.id === activeCompany.id);
  }, [companiesList, activeCompany]);

  return (
    <>
      {(isLoading || isSwitching) && (
        <div>
          <LoadingIcon text="" />
        </div>
      )}
      {!isLoading && !isSwitching && (
        <Trigger ref={triggerRef} onClick={handleMenuOpen}>
          {renderAvatar(activeCompany, 'small')}
          <h4>{trading_name && trading_name.toLowerCase()}</h4>
          <IoChevronDown
            className="ml-2 mr-1"
            size="0.8em"
            color="var(--color-text-primary)"
          />
        </Trigger>
      )}
      {/* <CompanyFormModal
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onToggleForm={() => setIsModalOpen(false)}
      /> */}
      <ContextMenu
        onClick={e => {
          e.preventDefault();
        }}
        theme="company-switcher"
        menuId={MENU_ID}
      >
        <ActiveCompany onClick={() => handleEditCompany(activeCompany.id)}>
          {renderAvatar(activeCompany)}
          <div className="ml-2 d-flex flex-column">
            <strong>{relatedActiveCompany?.trading_name.toLowerCase()}</strong>
            <small>
              <SubscriptionInfo>
                <PlanName>
                  {relatedActiveCompany?.subscription_plan_name.split(' ')[0]}
                </PlanName>
                <Dot>•</Dot>
                <UserCount>
                  {`${relatedActiveCompany?.user_count} ${pluralize('usuário', relatedActiveCompany?.user_count)}`}
                </UserCount>
              </SubscriptionInfo>
            </small>
          </div>
          <div className="ml-auto d-flex justify-content-center">
            <LuSettings size={24} />
          </div>
        </ActiveCompany>
        <MenuItem onClick={() => handleRedirect('/configuracoes/visao-geral')}>
          <LuSettings size={14} className="mr-2" />
          Configurações
        </MenuItem>
        {/* <Submenu
          label={
            <SubMenuLabel>
              <LuComponent size={14} className="mr-2" />
              Contas, categorias e mais
            </SubMenuLabel>
          }
          arrow={<IoChevronForward className="no-margin-right" size={13} />}
        >
          <MenuItem onClick={() => handleRedirect('/contas-bancarias')}>
            <LuLandmark size={14} className="mr-2 with-color" />
            Contas bancárias
          </MenuItem>
          <MenuItem onClick={() => handleRedirect('/categorias')}>
            <LuBookmark size={14} className="mr-2 with-color" />
            Categorias
          </MenuItem>
          <MenuItem onClick={() => handleRedirect('/centros-de-custo')}>
            <LuFolderTree size={14} className="mr-2 with-color" />
            Centro de custos
          </MenuItem>
          <MenuItem onClick={() => handleRedirect('/tags')}>
            <LuTags size={14} className="mr-2 with-color" />
            Tags
          </MenuItem>
          <Separator />
          <MenuItem onClick={() => handleRedirect('/recibos')}>
            <LuFolderTree size={14} className="mr-2 with-color" />
            Recibos
          </MenuItem>
          <MenuItem onClick={() => handleRedirect('/configuracoes/logs')}>
            <LuTags size={14} className="mr-2 with-color" />
            Logs
          </MenuItem>
          <Separator />
          <MenuItem onClick={() => handleRedirect('/importar-extrato')}>
            <LuFolderTree size={14} className="mr-2 with-color" />
            Importar extrato OFX
          </MenuItem>
          <MenuItem onClick={() => handleRedirect('/importar')}>
            <LuTags size={14} className="mr-2 with-color" />
            Importar planilha xlsx
          </MenuItem>
        </Submenu> */}
        <MenuItem onClick={() => handleRedirect('/configuracoes/assinatura')}>
          <LuCreditCard size={14} className="mr-2" />
          Assinatura
          {currentCompany && (
            <span className="ml-auto">
              <SubscriptionStatusTag status={currentCompany.subscription_status} />
            </span>
          )}
        </MenuItem>
        <MenuItem onClick={() => handleRedirect('/configuracoes/usuarios')}>
          <LuUsers size={14} className="mr-2" />
          Usuários
        </MenuItem>
        {!isEmpty(otherCompanies) && (
          <div className="mt-2 mb-2">
            <SearchMenuItem>
              <small>MINHAS EMPRESAS</small>
              <span>
                <Button
                  variant="link"
                  size="sm"
                  className="p-0 m-0 d-flex justify-content-center"
                  onClick={() => {
                    handleRedirect('/empresas');
                  }}
                >
                  Ver todas
                  <IoChevronForward size={12} className="ml-1" />
                </Button>
              </span>
            </SearchMenuItem>
            {otherCompanies.map(company => (
              <CompanyListItem
                key={company.id}
                className="p-2 d-flex align-items-center"
                onClick={() => handleActivateCompany(company)}
              >
                {renderAvatar(company)}
                <div
                  className="ml-2 d-flex flex-column align-items-start justify-content-center"
                  style={{ flex: 1 }}
                >
                  <strong>{company.trading_name.toLowerCase()}</strong>
                  <SubscriptionInfo>
                    <PlanName>{company.subscription_plan_name.split(' ')[0]}</PlanName>
                    <Dot>•</Dot>
                    <UserCount>
                      {`${company.user_count} ${pluralize('usuário', company.user_count)}`}
                    </UserCount>
                  </SubscriptionInfo>
                </div>
                {/* <RoleContainer>
                  <RoleName variant={company.is_manager ? 'default' : 'default'}>
                    {company.is_manager ? 'GERENTE' : 'COLABORADOR'}
                  </RoleName>
                </RoleContainer> */}
              </CompanyListItem>
            ))}
          </div>
        )}
        <Footer>
          <AddCompanyButton variant="link" onClick={() => setIsModalOpen(true)}>
            <IoAddOutline size={20} className="mr-2" />
            Adicionar outra conta
          </AddCompanyButton>
        </Footer>
      </ContextMenu>
    </>
  );
}

CompanySwitcher.propTypes = {
  isLoading: false,
  companiesList: [],
  activeCompany: {},
};

CompanySwitcher.propTypes = {
  activeCompany: PropTypes.object,
  isLoading: PropTypes.bool,
  companiesList: PropTypes.array,

  onFetchActiveCompany: PropTypes.func.isRequired,
  onFetchMyCompanies: PropTypes.func.isRequired,
  onActivateCompany: PropTypes.func.isRequired,
  onFetchCompanies: PropTypes.func.isRequired,
};

export default CompanySwitcher;
