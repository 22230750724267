const isUserMigrated = user => {
  if (!user) {
    return false;
  }

  const { new_subscription_system } = user;

  return new_subscription_system;
};

export default isUserMigrated;
