import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';

const cancelSubscription = (params, callback, errorCallback) => {
  return (dispatch) => {
    dispatch(request());

    service.cancelSubscription(params).then(
      (response) => {
        const { data } = response;

        dispatch(success(data));

        if (callback) {
          callback(data);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));

      if (errorCallback) {
        errorCallback(error);
      }
    });
  };

  function request() {
    return {
      type: constants.CANCEL_SUBSCRIPTION_REQUEST,
      payload: {},
    };
  }
  function success(data) {
    return {
      type: constants.CANCEL_SUBSCRIPTION_SUCCESS,
      payload: {
        data,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.CANCEL_SUBSCRIPTION_FAILURE,
      payload: {
        error,
      },
    };
  }
}

export default cancelSubscription;