import { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import {
  LuActivity,
  LuBell,
  LuBookmark,
  LuBuilding,
  LuCreditCard,
  LuDatabaseBackup,
  LuFolderTree,
  LuHome,
  LuReceipt,
  LuTags,
  LuUnplug,
  LuUser,
  LuUsers,
} from 'react-icons/lu';

import isUserMigrated from 'helpers/isUserMigrated';

import { PageHeader } from '_components/_core';

import { Overview, Logs, ApiKey, Subscription as SubscriptionV2 } from './components';

import Users from '../Users/UsersContainer';
import Subscription from '../Subscription/SubscriptionContainer';
import Profile from '../Profile/ProfileContainer';
import Companies from '../Companies/CompaniesContainer';
import Preferences from '../Preferences/PreferencesContainer';
import ExportFinancialData from '../ExportFinancialData/ExportFinancialDataContainer';
import DeleteFinancialData from '../DeleteFinancialData/DeleteFinancialDataContainer';

import { CustomTabs, CustomTab, Grid, LeftPanel } from './styles';

function SettingsV2({ user, activeCompany, onFetchMarketSegments }) {
  const location = useLocation();
  const history = useHistory();
  const { path } = useRouteMatch();

  const isMigrated = useMemo(() => {
    return isUserMigrated(user);
  }, [user]);

  const tabsCompany = useMemo(() => {
    const tabs = [];

    if (isMigrated) {
      tabs.push({
        id: 'visao-geral',
        title: 'Visão geral',
        icon: <LuHome className="mr-3" />,
      });
    }

    tabs.push({
      id: 'assinatura',
      title: 'Assinatura',
      icon: <LuCreditCard className="mr-3" />,
    });

    tabs.push({
      id: 'usuarios',
      title: 'Usuários',
      icon: <LuUsers className="mr-3" />,
    });

    tabs.push({
      id: 'integracoes',
      title: 'Integrações',
      icon: <LuUnplug className="mr-3" />,
    });
    tabs.push({
      id: 'logs',
      title: 'Logs de atividades',
      icon: <LuActivity className="mr-3" />,
    });
    tabs.push({
      id: 'exportar-dados',
      title: 'Exportar dados',
      icon: <LuDatabaseBackup className="mr-3" />,
    });

    return tabs;
  }, [isMigrated]);

  const tabsData = [
    {
      id: 'contas-bancarias',
      title: 'Contas bancárias',
      icon: <LuBuilding className="mr-3" />,
      isExternal: true,
    },
    {
      id: 'categorias',
      title: 'Categorias',
      icon: <LuBookmark className="mr-3" />,
      isExternal: true,
    },
    {
      id: 'centro-de-custos',
      title: 'Centro de custos',
      icon: <LuFolderTree className="mr-3" />,
      isExternal: true,
    },
    {
      id: 'tags',
      title: 'Marcações (tags)',
      icon: <LuTags className="mr-3" />,
      isExternal: true,
    },
    {
      id: 'recibos',
      title: 'Modelos de recibos',
      icon: <LuReceipt className="mr-3" />,
      isExternal: true,
    },
  ];

  const tabsUser = [
    {
      id: 'dados-pessoais',
      title: 'Dados pessoais',
      icon: <LuUser className="mr-3" />,
    },
    {
      id: 'empresas',
      title: 'Minhas empresas',
      icon: <LuBuilding className="mr-3" />,
    },
    {
      id: 'preferencias',
      title: 'Preferências',
      icon: <LuBell className="mr-3" />,
    },
  ];

  useEffect(() => {
    onFetchMarketSegments();
  }, [onFetchMarketSegments]);

  const handleTabChange = useCallback(
    tabId => {
      history.push(`${path}/${tabId}`);
    },
    [history, path],
  );

  const renderTabs = useCallback(
    tabs => {
      return (
        <CustomTabs variant="vertical">
          {tabs.map(tab => (
            <CustomTab
              key={tab.id}
              onClick={() => handleTabChange(tab.id)}
              className={classNames({
                active: location.pathname.includes(tab.id),
              })}
            >
              {tab.icon}
              {tab.title}
            </CustomTab>
          ))}
        </CustomTabs>
      );
    },
    [handleTabChange, location],
  );

  if (!activeCompany) {
    return null;
  }

  return (
    <Container fluid className="content-wrapper">
      <PageHeader title="Configurações" fixedHeader variant="small" />
      <Grid>
        <LeftPanel>
          <p className="ml-3 d-flex justify-content-start align-items-center font-weight-bold">
            Sobre você
          </p>
          {renderTabs(tabsUser)}
          <hr />
          <p className="ml-3 mb-2 d-flex justify-content-start align-items-center font-weight-bold">
            Sobre a empresa
          </p>
          {renderTabs(tabsCompany)}
          <hr />
          {renderTabs(tabsData)}
          <hr />
        </LeftPanel>
        <div className="ml-3">
          <Switch>
            <Route path={`${path}/visao-geral`} component={Overview} />
            <Route
              path={`${path}/usuarios`}
              render={props => <Users {...props} isCompact />}
            />
            {!isMigrated && (
              <Route
                path={`${path}/assinatura`}
                render={props => <Subscription {...props} isCompact />}
              />
            )}
            {isMigrated && (
              <Route
                path={`${path}/assinatura`}
                render={props => <SubscriptionV2 {...props} isCompact />}
              />
            )}
            <Route path={`${path}/logs`} component={Logs} />
            <Route path={`${path}/integracoes`} component={ApiKey} />
            <Route
              path={`${path}/exportar-dados`}
              render={props => <ExportFinancialData {...props} isCompact />}
            />
            <Route
              path={`${path}/excluir-dados`}
              render={props => <DeleteFinancialData {...props} isCompact />}
            />
            <Route
              path={`${path}/dados-pessoais`}
              render={props => <Profile {...props} isCompact />}
            />
            <Route
              path={`${path}/empresas`}
              render={props => <Companies {...props} isCompact />}
            />
            <Route
              path={`${path}/preferencias`}
              render={props => <Preferences {...props} isCompact />}
            />
            <Route path={path} exact>
              <h3>Selecione uma opção</h3>
            </Route>
          </Switch>
        </div>
      </Grid>
    </Container>
  );
}

SettingsV2.defaultProps = {
  activeCompany: {
    manager: {},
  },
};

SettingsV2.propTypes = {
  activeCompany: PropTypes.object,
  onFetchMarketSegments: PropTypes.func.isRequired,
};

export default SettingsV2;
