import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import { Card, CardBody, Tag, Alert, Button, PageHeader } from '_components/_core';
import { Container } from 'react-bootstrap';
import FORMATTERS from 'helpers/formatters';

import { SubscriptionAlerts } from '_components/_shared';
import { PlansModal } from './components';
import {
  Plan,
  PlanName,
  PlanDescription,
  StyledAlert,
  StyledAlertHeading,
  AlertDescription,
} from './styles';

function Subscription({
  subscription,
  onFetchSubscription,
  onFetchPlans,
  onCreateUpdatePaymentSession,
  isCompact,
}) {
  const [showPlansModal, setShowPlansModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { plan_usage } = subscription || {};
  const { companies = {}, users = {} } = plan_usage || {};

  const companiesPercentage = (Number(companies.used) / Number(companies.max)) * 100;
  const companiesClass = companiesPercentage > 80 ? 'danger' : 'success';

  const usersPercentage = (Number(users.used) / Number(users.max)) * 100;
  const usersClass = usersPercentage > 80 ? 'danger' : 'success';

  useEffect(() => {
    onFetchSubscription();
    onFetchPlans();
  }, [onFetchSubscription, onFetchPlans]);

  const renderStatusTag = () => {
    const { status, trial_end } = subscription;

    if (status === 'trialing') {
      return (
        <Tag variant="info" className="ml-2">
          {`Teste gratuito até ${DateTime.fromISO(trial_end, { zone: 'utc' }).toFormat('dd/MM/yyyy')}`}
        </Tag>
      );
    }

    if (status === 'trial-ended') {
      return (
        <Tag variant="danger" className="ml-2">
          {`Teste gratuito encerrado em ${DateTime.fromISO(trial_end, { zone: 'utc' }).toFormat('dd/MM/yyyy')}`}
        </Tag>
      );
    }

    if (status === 'canceled') {
      return (
        <Tag variant="danger" className="ml-2">
          Cancelado
        </Tag>
      );
    }

    if (status === 'past_due') {
      return (
        <Tag variant="danger" className="ml-2">
          Vencida
        </Tag>
      );
    }

    if (status === 'active') {
      return (
        <Tag variant="success" className="ml-2">
          Ativo
        </Tag>
      );
    }

    return null;
  };

  const renderSubscriptionDetails = () => {
    const { status, plan_price, current_period_end } = subscription;

    if (status === 'canceled') {
      return 'Sem cobranças futuras';
    }

    if (status === 'trialing') {
      return `${FORMATTERS.NUMBER(plan_price)}`;
    }

    if (status === 'past_due') {
      return 'Vencida';
    }

    if (status === 'active') {
      return `Próxima cobrança: ${FORMATTERS.NUMBER(plan_price)} em ${FORMATTERS.DATE_DDMMYYYY(current_period_end)}`;
    }

    return '';
  };

  const handleCreateUpdatePaymentSession = useCallback(
    e => {
      e.preventDefault();

      setIsLoading(true);

      onCreateUpdatePaymentSession(session_url => {
        const anchor = document.createElement('a');
        anchor.href = session_url;
        anchor.style.display = 'none';

        document.body.appendChild(anchor);
        anchor.click();

        setIsLoading(false);

        document.body.removeChild(anchor);
      });
    },
    [onCreateUpdatePaymentSession],
  );

  if (!subscription) {
    return null;
  }

  return (
    <Container
      fluid
      className={classNames({
        'content-wrapper': !isCompact,
      })}
    >
      {!isCompact && <PageHeader title="Meu Plano" variant="small" />}
      <SubscriptionAlerts />
      <Row className="mb-3">
        <Col sm={12} lg={12}>
          <Row className="mb-3">
            <Col xs={12} sm={12} lg={12}>
              <Card>
                <CardBody className="p-3">
                  <Row>
                    <Col xs={9}>
                      <Plan>
                        <PlanName className="mb-0">
                          <span>{`Zenply ${subscription?.plan_name}`}</span>
                        </PlanName>
                        <PlanDescription>{renderSubscriptionDetails()}</PlanDescription>
                      </Plan>
                    </Col>
                    <Col
                      xs={3}
                      lg={3}
                      className="d-flex justify-content-end align-items-center"
                    >
                      {renderStatusTag()}
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg={3}>
                      <Form.Group>
                        <Form.Label className="d-block">
                          Usuários
                          <span className="float-right text-muted font-weight-light">
                            {`${users.used} de ${users.max}`}
                          </span>
                        </Form.Label>
                        <ProgressBar
                          animated
                          now={usersPercentage}
                          variant={usersClass}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={3}>
                      <Form.Group>
                        <Form.Label className="d-block">
                          Empresas
                          <span className="float-right text-muted font-weight-light">
                            {`${companies.used} de ${companies.max}`}
                          </span>
                        </Form.Label>
                        <ProgressBar
                          animated
                          now={companiesPercentage}
                          variant={companiesClass}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {subscription && subscription.status === 'trialing' && (
            <Row>
              <Col sm={12} md={12}>
                <PlansModal
                  subscription={subscription}
                  isVisible={showPlansModal}
                  onModalToggle={() => setShowPlansModal(!showPlansModal)}
                />
              </Col>
            </Row>
          )}
          {subscription &&
            (subscription.status === 'active' || subscription.status === 'past_due') && (
              <>
                <Row>
                  <Col className="mb-3">
                    <StyledAlert variant="primary">
                      <AlertDescription>
                        <Col xs={9} md={10} className="pl-0">
                          <StyledAlertHeading>
                            Alterar forma de pagamento
                          </StyledAlertHeading>
                          Gerenciar suas formas de pagamento acessando o portal do
                          cliente.
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center">
                          <Button
                            variant="inverse-dark"
                            onClick={handleCreateUpdatePaymentSession}
                            isLoading={isLoading}
                            disabled={isLoading}
                          >
                            Acessar
                          </Button>
                        </Col>
                      </AlertDescription>
                    </StyledAlert>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12}>
                    <Alert
                      title="Precisa trocar ou cancelar o plano?"
                      description="Entre em contato com nosso suporte no e-mail contato@zenply.com.br para alterar ou cancelar o seu plano. Em breve faremos melhorias para que você possa fazer isso diretamente no sistema."
                    />
                  </Col>
                </Row>
              </>
            )}
        </Col>
      </Row>
    </Container>
  );
}

Subscription.defaultProps = {
  subscription: null,
  isCompact: false,
};

Subscription.propTypes = {
  onFetchPlans: PropTypes.func.isRequired,
  onFetchSubscription: PropTypes.func.isRequired,
  subscription: PropTypes.object,
  onCreateUpdatePaymentSession: PropTypes.func.isRequired,
  isCompact: PropTypes.bool,
};

export default Subscription;
