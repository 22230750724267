import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsPlus } from 'react-icons/bs';
import classNames from 'classnames';

import { Button, PageHeader, TableLookup } from '_components/_core';
import { Pagination, ItemsPerPage } from '_components/_core/Table/components';
import { useTable } from '_components/_core/Table/utils';

import { Container } from 'react-bootstrap';
import { UsersModal } from './components';
import { DEFAULT_COLUMNS, TABLE_COLUMNS } from './utilities';

const tableConfig = {
  defaultFilters: {},
  defaultSorting: { field: 'name', order: 'asc' },
  defaultPagination: { currentPage: 1, itemsPerPage: 10 },
};

function Users({ total, users, onFetchUsers, onDeleteUser, isCompact }) {
  const { pagination, onPageChange, onPageSizeChange } = useTable({
    ...tableConfig,
    data: users,
    onFetchData: onFetchUsers,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeleteUser = useCallback(
    id => {
      onDeleteUser(id, () => {
        onFetchUsers(pagination);
      });
    },
    [onDeleteUser, onFetchUsers, pagination],
  );

  return (
    <Container
      fluid
      className={classNames({
        'content-wrapper': !isCompact,
      })}
    >
      {!isCompact && (
        <PageHeader
          title="Usuários"
          variant="small"
          sideContent={
            <Button variant="dark" icon={<BsPlus />} onClick={() => setIsModalOpen(true)}>
              Convidar Usuário
            </Button>
          }
        />
      )}
      {isCompact && (
        <Button
          variant="dark"
          className="mb-3"
          icon={<BsPlus />}
          onClick={() => setIsModalOpen(true)}
        >
          Convidar Usuário
        </Button>
      )}
      <UsersModal
        isVisible={isModalOpen}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
      />
      <TableLookup
        keyName="id"
        data={users}
        columns={TABLE_COLUMNS}
        defaultColumns={DEFAULT_COLUMNS}
        onDelete={handleDeleteUser}
      />
      <Row>
        <Col sm={4}>
          <ItemsPerPage
            itemsPerPage={pagination.itemsPerPage}
            onChange={onPageSizeChange}
            className="mt-3"
          />
        </Col>
        <Col className="d-flex flex-row-reverse">
          <Pagination {...pagination} total={total} onPageChange={onPageChange} />
        </Col>
      </Row>
    </Container>
  );
}

Users.defaultProps = {
  isCompact: false,
};

Users.propTypes = {
  users: PropTypes.object,
  total: PropTypes.number.isRequired,
  onFetchUsers: PropTypes.func.isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  isCompact: PropTypes.bool,
};

export default Users;
