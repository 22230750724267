import createIntegration from './createIntegration';
import updateIntegration from './updateIntegration';
import getIntegrationByType from './getIntegrationByType';
import getAllIntegrations from './getAllIntegrations';
import deleteIntegration from './deleteIntegration';

export default {
  createIntegration,
  updateIntegration,
  getIntegrationByType,
  getAllIntegrations,
  deleteIntegration,
};
