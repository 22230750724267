import styled, { css } from 'styled-components';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';

export const StyledNavbar = styled(Navbar)`
  background-color: var(--navbar-color);
  box-shadow: var(--navbar-box-shadow);
  border-bottom: var(--navbar-border-bottom);
  z-index: 995 !important;
  height: 49px;
  padding: 0 1rem;

  ${({ isMobile }) => isMobile && css`
    &&& {
      {
        z-index: 999 !important;
      }
    }
  `}
`;

export const NavbarContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ isMobile }) => isMobile && css`
    &&& {
      {
        justify-content: space-between;
      }
    }
  `}
`;

export const StyledButton = styled(Button)`
  color: var(--color-text-primary);
`;

export const NavbarBrandContainerMobile = styled.div`
  /* display: flex; */
  /* text-align: center; */
  padding: 0.5rem 1rem;

  @media (min-width: 280px) {
    /* padding: 0.5rem -0rem; */
  }
`;

export const StyledNavbarBrandMobile = styled(Navbar.Brand)`
  display: flex;
  margin-right: 0 !important;

  a.navbar-brand {
    margin-right: 0 !important;
    color: var(--color-text-primary) !important;
  }
`;

export const NavbarBrandContainerDesktop = styled.div`
  text-align: center;
  padding: 0.5rem 0;

  
`;

export const StyledNavbarBrandDesktop = styled(Navbar.Brand)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0 !important;
  
  a.navbar-brand {
    margin-right: 0 !important;
    color: var(--color-text-primary) !important;
  }
`;
