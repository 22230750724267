import styled, { css } from 'styled-components';

export const EditIcon = styled.img`
  display: inline-flex;
  width: 80px;
  border-radius: 50%;
  margin-right: -80px;
  opacity: 0;

  ${({ variant }) =>
    variant === 'small' &&
    css`
    width: 45px;
    margin-right: -45px;
  `}

  &:hover {
    opacity: 0.8;
  }
`;

export const AvatarWrapper = styled.div`
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 10%;

  ${({ variant }) =>
    variant === 'small' &&
    css`
    width: 45px;
    height: 45px;
  `}
`;

export const StyledAvatar = styled.img`
  width: 80px;
  max-height: 80px;

  ${({ variant }) =>
    variant === 'small' &&
    css`
    width: 45px;
    max-height: 45px;
  `}
`;

export const StyledInput = styled.input.attrs({
  type: 'file',
})`
 display: none;
`;

export const StyledLabel = styled.label`
  cursor: pointer;
  margin: 0;
  padding-left: 13px;
  padding-right: 30px;
`;
