import constants from '../../_constants/apiKeys.constants';
import service from '../../../services/apiKeys.service';

const updateKey = (values, callback) => {
  return (dispatch) => {
    dispatch(request(values));

    service.updateKey(values).then(
      (response) => {
        dispatch(success(response.data));

        if (callback) {
          callback();
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.API_KEYS_UPDATE_REQUEST,
      payload: {},
    };
  }
  function success(apiKeys) {
    return {
      type: constants.API_KEYS_UPDATE_SUCCESS,
      payload: { apiKeys },
    };
  }
  function failure(error) {
    return {
      type: constants.API_KEYS_UPDATE_FAILURE,
      payload: error,
    };
  }
};

export default updateKey;
