import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa';

import { useMediaQuery } from 'helpers';
import FORMATTERS from 'helpers/formatters';

import {
  CustomCard,
  CustomCardBody,
  CustomCardHeader,
  CustomBreadcrumb,
  CustomBreadcrumbItem,
} from '../../../../styles';

import { StyledTable } from './styles';

function PaymentsHistory({
  activeCompany,
  payments,
  onFetchPayments,
  onChangeView,
}) {
  const { isMobile } = useMediaQuery();

  useEffect(() => {
    onFetchPayments();
  }, [onFetchPayments, activeCompany]);

  return (
    <>
      <CustomBreadcrumb>
        <CustomBreadcrumbItem
          onClick={() => onChangeView('DEFAULT')}
        >
          Assinatura
        </CustomBreadcrumbItem>
        <CustomBreadcrumbItem active>
          Histórico de Pagamentos
        </CustomBreadcrumbItem>
      </CustomBreadcrumb>
      <Row>
        <Col>
          <CustomCard>
            <CustomCardHeader>
              <h3>
                Histórico de pagamentos
              </h3>
            </CustomCardHeader>
            <CustomCardBody noPadding>
              <StyledTable className={`table table-hover ${isMobile ? 'table-responsive' : ''} `}>
                <thead>
                  <tr>
                    <th width="2%" className="text-center">Situação</th>
                    <th width="2%" className="text-center">Data</th>
                    <th width="10%" className="text-left">Descrição</th>
                    <th width="2%" className="text-center">Valor</th>
                    <th width="2%" className="text-center">Duração</th>
                    <th width="2%" className="text-center">Nota Fiscal</th>
                    <th width="2%" className="text-center">PDF</th>
                    <th width="2%" className="text-center">XML</th>
                  </tr>
                </thead>
                <tbody>
                  {payments.map((payment) => (
                    <tr key={payment._id}>
                      <td className="text-center">
                        {FORMATTERS.PAYMENT_PAYMENT_STATUS(payment.payment_status)}
                      </td>
                      <td className="text-center">
                        {FORMATTERS.DATE_DDMMYYYY(payment.payment_date)}
                      </td>
                      <td className="text-left">
                        {FORMATTERS.PAYMENT_PROVIDER_REASON(payment.payment_provider_reason)}
                      </td>
                      <td className="text-center">
                        <strong>
                          {FORMATTERS.NUMBER(payment.payment_amount)}
                        </strong>
                      </td>
                      <td className="text-center">
                        {FORMATTERS.PAYMENT_DURATION(payment)}
                      </td>
                      <td className="text-center">
                        {FORMATTERS.PAYMENT_INVOICE_STATUS(payment.invoice_status)}
                      </td>
                      <td className="text-center">
                        <a target="_blank" href={payment.invoice_pdf_url} rel="noreferrer">
                          <FaDownload />
                        </a>
                      </td>
                      <td className="text-center">
                        <a target="_blank" href={payment.invoice_xml_url} rel="noreferrer">
                          <FaDownload />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </CustomCardBody>
          </CustomCard>
        </Col>
      </Row>
    </>
  );
}

PaymentsHistory.defaultProps = {
  payments: [],
  activeCompany: {},
};

PaymentsHistory.propTypes = {
  payments: PropTypes.array,
  onFetchPayments: PropTypes.func,
  activeCompany: PropTypes.object,
  onChangeView: PropTypes.func,
};

export default PaymentsHistory;
