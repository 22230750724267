import React from 'react';

function EmptyMessage() {
  return (
    <div className="d-flex justify-content-center align-items-center pt-5 pb-5">
      <p className="m-0 p-0">Nenhum registro encontrado</p>
    </div>
  );
}

export default EmptyMessage;
