import HttpService from './http.service';

export default {
  getAllPlans,
  createCheckoutSession,
  createAuthorizedCheckoutSession,
  getUserSubscriptionStatus,
  updatePlan,
  createUpdatePaymentSession,
  getCompanySubscription,
  getCompanySubscriptionPlans,
  updateBillingInformation,
  createCheckout,
  cancelSubscription,
};

async function getAllPlans() {
  return HttpService.get('/subscription/plans');
}

async function createCheckoutSession(params) {
  return HttpService.post('/checkout-session', params);
}

async function createAuthorizedCheckoutSession(params) {
  return HttpService.post('/authorized-checkout-session', params);
}

async function getUserSubscriptionStatus() {
  return HttpService.get('/subscription');
}

async function updatePlan(params) {
  return HttpService.put('/subscription', params);
}

async function createUpdatePaymentSession() {
  return HttpService.post('/update-payment-session');
}

async function getCompanySubscription() {
  return HttpService.get('/company-subscription');
}

async function getCompanySubscriptionPlans() {
  return HttpService.get('/company-subscription-plans');
}

async function updateBillingInformation(params) {
  return HttpService.put('/billing-information', params);
}

async function createCheckout(params) {
  return HttpService.post('/create-checkout', params);
}

async function cancelSubscription(params) {
  return HttpService.post('/cancel-subscription', params);
}
