import styled, { css } from 'styled-components';
import ListGroup from 'react-bootstrap/ListGroup';

import { Button } from '_components/_core';
import { Form } from 'react-bootstrap';

export const Menu = styled.div`
  font-family: var(--font-family);
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
  rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
  rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, 
  rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: var(--card-border);
  background-color: var(--card-bg-color);

  ${({ isFixed }) => isFixed && css`
    position: sticky;
    top: 60px;
    z-index: 980;  
  `}
`;

export const MenuCategory = styled.span`
  padding: 18px 20px 14px 20px;
  color: rgba(26,51,83,.6);
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr; 
  padding: 8px;
`;

export const MenuItem = styled(ListGroup.Item)`
  &.list-group-item {
    cursor: pointer;
    border: none;
    font-weight: 500;
    border-radius: 4px;
    padding: 4px;
    margin-left: 8px;
    font-weight: 400;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    &:hover {
      color: var(--color-text-primary);
    }
  }

  &.list-group-item.active {
    color: var(--text-color-primary);
    background-color: var(--color-text-primary-light);
    font-weight: 500;
  }  
`;

export const StyledTable = styled.table`  
  &&& {
    width: 100%;
    font-weight: 400;

    thead tr th {
      height: 32px;
      line-height: 20px;
      padding: 0 9px;
      position: relative;
      background: #FFFFFF;
      vertical-align: middle;
      border-left: 1px solid #D4D7DC;
      border-bottom: 2px solid #D4D7DC;
      text-align: left;
      font-size: 0.78em;
      font-weight: 500;
      text-transform: uppercase;
    }
  
    thead tr th:first-child {
      border-left: none;
    }

    tbody tr td {
      line-height: 20px;
      padding: 9px 9px;
      vertical-align: middle;
      border-top: 1px solid #D4D7DC;
      display: table-cell;
      align-items: center;
      justify-content: center;
      font-size: 0.78em;
    }

    .table-dre {
      tbody tr td {
        background-color: #F9F9F9;
      }
    }
  }
`;

export const CashflowByTypeTotalRow = styled.tr`
  td {
    border-top-width: 3px !important;
    font-size: 1.05em !important;
    font-weight: 600 !important;
  }
`;

export const StyledDRETable = styled.table`  
  &&& {
    font-weight: 400;

    thead tr th {
      height: 32px;
      line-height: 20px;
      padding: 0 9px;
      position: relative;
      background: #FFFFFF;
      vertical-align: middle;
      border-left: 1px solid #D4D7DC;
      border-bottom: 2px solid #D4D7DC;
      text-align: left;
      font-size: 0.9em;
      font-weight: 500;
      text-transform: uppercase;
    }

    thead tr th:first-child {
      border-left: none;
    }

    tbody tr td {
      line-height: 20px;
      padding: 9px 9px;
      vertical-align: middle;
      border-top: 1px solid #D4D7DC;
    }

    tbody tr td:last-child {
      font-size: 15.6px;
    }

    tbody tr:last-child td {
      font-size: 15.6px;
    }
  }
`;

export const StyledShowOthersButton = styled(Button)`
  padding: 0;
  margin: 0;
  color: #21ABF6;
  text-decoration: none;
  cursor: pointer;
  font-size: 12.8px !important;

  &:hover {
    text-decoration: underline;
    color: #21ABF6;
  }
`;

export const StyledNavigationHeaderRow = styled.tr`
  th {
    text-transform: none !important;
    padding-bottom: 12px !important;
    font-size: 0.9em !important;
    font-weight: 400 !important;
    vertical-align: middle !important;
    box-sizing: content-box !important;
  }
`;

export const BreadcrumbItem = styled.span`

  ${({ isClickable }) => isClickable && css`
    color: #21ABF6;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  `}

  ${({ isClickable }) => !isClickable && css`
    color: #8D9096;
    cursor: default;
    pointer-events: none;
  `}

`;

export const Separator = styled.span`
      color: #8D9096;
`;

export const UnclickableBreadcrumb = styled.span`

`;

export const StyledTransactionRow = styled.tr`
  td {
    cursor: pointer;
  }
`;

export const StyledClickableRow = styled.tr`
  td {
    cursor: pointer;
  }

  td:first-child {
    
    span {
      color: #21ABF6;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const StyledReportTableRow = styled.tr`

  ${({ isClickable }) => isClickable && css`
    td {
      cursor: pointer;
    }

    td:first-child {    
      span {
        color: #21ABF6;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  `}
`;

export const StyledCheckbox = styled(Form.Check)`
  label {
    padding-left: 0 !important;
    cursor: pointer;
  }
`;
