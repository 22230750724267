import { connect } from 'react-redux';
import { compose } from 'redux';

import Overview from './Overview';

const mapStateToProps = (state) => ({
  activeCompany: state.company.activeCompany,
});

export default compose(
  connect(mapStateToProps, null),
)(Overview);
