import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Formik, useFormikContext } from 'formik';
import classNames from 'classnames';
import { debounce, isEqual } from 'lodash';

import {
  Card,
  CardHeader,
  CardBody,
  FormSwitchField,
  PageHeader,
} from '_components/_core';
import { Container } from 'react-bootstrap';

const SubmitListener = () => {
  const formik = useFormikContext();
  const [lastValues, updateState] = React.useState(formik.values);

  const submitForm = useCallback(
    debounce(
      () => {
        formik.submitForm();
      },
      100,
      { maxWait: 100 },
    ),
    [],
  );

  React.useEffect(() => {
    const valuesEqualLastValues = isEqual(lastValues, formik.values);

    if (!valuesEqualLastValues) {
      updateState(formik.values);
    }

    if (!valuesEqualLastValues && formik.isValid) {
      submitForm();
    }
  }, [formik.values, formik.initialValues, lastValues, submitForm, formik.isValid]);

  return null;
};

function Preferences({
  preferences,
  onSaveUserPreferences,
  onFetchUserPreferences,
  isCompact,
}) {
  useEffect(() => {
    onFetchUserPreferences();
  }, [onFetchUserPreferences]);

  const initialValues = useMemo(() => {
    const {
      show_all_bank_accounts,
      show_old_items_payment_confirmation,
      receive_expiring_transactions_mail,
    } = preferences || {};

    return {
      show_all_bank_accounts: show_all_bank_accounts || false,
      show_old_items_payment_confirmation: show_old_items_payment_confirmation || false,
      receive_expiring_transactions_mail: receive_expiring_transactions_mail || false,
    };
  }, [preferences]);

  return (
    <Container
      fluid
      className={classNames({
        'content-wrapper': !isCompact,
      })}
    >
      {!isCompact && <PageHeader title="Preferências" variant="small" />}
      <Card>
        <CardHeader
          title="Preferências da Conta"
          description="Ajuste suas preferências de conta nessa tela."
        />
        <CardBody>
          <Formik
            initialValues={initialValues}
            onSubmit={values => onSaveUserPreferences(values)}
            enableReinitialize
          >
            {() => (
              <>
                <SubmitListener />
                <Row>
                  <Form.Group as={Col} lg={12}>
                    <Form.Label>
                      Visualizar todas as contas bancárias como padrão
                    </Form.Label>
                    <FormSwitchField name="show_all_bank_accounts" />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} lg={12}>
                    <Form.Label>
                      Confirmar data de pagamento ao marcar um item antigo como pago
                    </Form.Label>
                    <FormSwitchField name="show_old_items_payment_confirmation" />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} lg={12}>
                    <Form.Label>Receber notificações de vencimento por e-mail</Form.Label>
                    <FormSwitchField name="receive_expiring_transactions_mail" />
                  </Form.Group>
                </Row>
              </>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Container>
  );
}

Preferences.defaultProps = {
  preferences: {},
  isCompact: false,
};

Preferences.propTypes = {
  preferences: PropTypes.object,
  onSaveUserPreferences: PropTypes.func,
  onFetchUserPreferences: PropTypes.func,
  isCompact: PropTypes.bool,
};

export default Preferences;
