import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';

import {
  Button,
  FormDateField,
  FormTextField,
} from '_components/_core';

import {
  StyledModal,
  ModalFooter,
} from './styles';

const ApiKeysModal = ({
  isVisible,
  onModalToggle,
  onCreateKey,
  isLoading,
  onFetchKeys,
  apiKey,
  onUpdateKey,
}) => {
  const handleCreateUpdateKey = useCallback((values, { resetForm }) => {
    if (values._id) {
      onUpdateKey(values, () => {
        onModalToggle();
        onFetchKeys();
        resetForm();
      });
    } else {
      onCreateKey(values, () => {
        onModalToggle();
        onFetchKeys();
        resetForm();
      });
    }
  }, [onCreateKey, onModalToggle, onFetchKeys, onUpdateKey]);

  const renderModalFooter = useCallback((handleSubmit, isValid) => (
    <ModalFooter>
      <Button variant="secondary" onClick={onModalToggle}>
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="dark"
        onClick={handleSubmit}
        isLoading={isLoading}
        disabled={!isValid || isLoading}
      >
        Criar Chave
      </Button>
    </ModalFooter>
  ), [onModalToggle, isLoading]);

  if (!isVisible) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        _id: apiKey ? apiKey._id : null,
        name: apiKey ? apiKey.name : null,
        description: apiKey ? apiKey.description : null,
        expire_at: apiKey ? apiKey.expire_at : null,
      }}
      onSubmit={handleCreateUpdateKey}
      enableReinitialize
    >
      {({ handleSubmit, isValid }) => (
        <>
          <StyledModal
            title={apiKey ? 'Editar Chave da API' : 'Criar Nova Chave da API'}
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(handleSubmit, isValid)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>Nome da Chave</Form.Label>
                  <FormTextField
                    name="name"
                    placeholder="Nome da Chave"
                    type="name"
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Data de Expiração</Form.Label>
                  <FormDateField
                    name="expire_at"
                    placeholder="Data de Expiração"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>Descrição</Form.Label>
                  <FormTextField
                    name="description"
                    placeholder="Descrição da Chave"
                    type="text"
                    required
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
};

export default ApiKeysModal;

ApiKeysModal.defaultProps = {
  isVisible: false,
};

ApiKeysModal.propTypes = {
  isVisible: PropTypes.bool,
  onModalToggle: PropTypes.func.isRequired,
  onCreateKey: PropTypes.func,
  isLoading: PropTypes.bool,
  onFetchKeys: PropTypes.func.isRequired,
  apiKey: PropTypes.object,
  onUpdateKey: PropTypes.func.isRequired,
};
