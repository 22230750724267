import constants from '../../_constants/integrations.constants';
import service from '../../../services/integrations.service';

const getAllIntegrations = (callback) => {
  return (dispatch) => {
    dispatch(request());

    service.getAllIntegrations().then(
      (response) => {
        dispatch(success(response));

        if (callback) {
          callback(response.data);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(data) {
    return {
      type: constants.FETCH_BANK_INTEGRATION_SETTINGS_REQUEST,
      payload: { data },
    };
  }
  function success(response) {
    return {
      type: constants.FETCH_BANK_INTEGRATION_SETTINGS_SUCCESS,
      payload: { response },
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_BANK_INTEGRATION_SETTINGS_FAILURE,
      payload: error,
    };
  }
};

export default getAllIntegrations;
