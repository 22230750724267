import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { LoadingIcon } from '_components/_shared';
import {
  Overview,
  PaymentsHistory,
  AdditionalUsers,
  Checkout,
  CancelSubscription,
} from './components';

function Subscription({ isLoading, subscription, activeCompany, onFetchSubscription }) {
  const [view, setView] = useState('DEFAULT');

  useEffect(() => {
    onFetchSubscription();
  }, [onFetchSubscription, activeCompany]);

  const handleChangeView = useCallback(newView => {
    setView(newView);
  }, []);

  const commonProps = useMemo(
    () => ({
      isLoading,
      subscription,
      onChangeView: handleChangeView,
    }),
    [isLoading, subscription, handleChangeView],
  );

  if (!isLoading && subscription && subscription.status === 'trialing') {
    return <Checkout {...commonProps} />;
  }

  return (
    <>
      {isLoading && <LoadingIcon className="mt-4" text="Carregando..." />}
      {!isLoading && (
        <>
          {view === 'DEFAULT' && <Overview {...commonProps} />}
          {view === 'PAYMENTS_HISTORY' && <PaymentsHistory {...commonProps} />}
          {view === 'ADDITIONAL_USERS' && <AdditionalUsers {...commonProps} />}
          {view === 'CANCEL_SUBSCRIPTION' && <CancelSubscription {...commonProps} />}
          {view === 'CHECKOUT' && <Checkout {...commonProps} />}
        </>
      )}
    </>
  );
}

Subscription.defaultProps = {
  payments: [],
  activeCompany: {},
  isLoading: false,
  subscription: null,
};

Subscription.propTypes = {
  isLoading: PropTypes.bool,
  payments: PropTypes.array,
  onFetchPayments: PropTypes.func,
  activeCompany: PropTypes.object,
  subscription: PropTypes.object,
  onFetchSubscription: PropTypes.func,
};

export default Subscription;
