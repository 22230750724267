import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useStripe } from '@stripe/react-stripe-js';
import {
  Card,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import {
  LuArrowRight,
  LuComponent,
  LuCreditCard,
  LuPencil,
} from 'react-icons/lu';
import { FaBarcode } from 'react-icons/fa';
import { IoArrowBack } from 'react-icons/io5';

import FORMATTERS from 'helpers/formatters';

import { Button } from '_components/_core';

import { BillingInformationModal } from '../..';
import { ButtonToggle, Details } from '../styles';

const CYCLE_MONTHS = {
  1: 'mensal',
  6: 'semestral',
  12: 'anual',
};

function SelectPaymentMethod({
  planId,
  plans,
  onCreateCheckout,
  onBackToPlans,
}) {
  const stripe = useStripe();

  const [isLoading, setIsLoading] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('CREDIT_CARD');

  const plan = plans.find((p) => p.id === planId);

  const hasDiscount = plan.cycle_months !== 1;

  const handleSubmitCheckout = useCallback(() => {
    setIsLoading(true);

    const successCallback = (data) => {
      setIsLoading(false);

      if (selectedPaymentMethod === 'CREDIT_CARD') {
        stripe.redirectToCheckout({ sessionId: data.session_id });
      }
    };

    const errorCallback = () => {
      setIsLoading(false);
    };

    const params = {
      plan_id: planId,
      payment_method: selectedPaymentMethod,
    };

    onCreateCheckout(params, successCallback, errorCallback);
  }, [onCreateCheckout, planId, selectedPaymentMethod, stripe]);

  const totals = useMemo(() => {
    const selectedPlan = plans.find((p) => p.id === planId);

    const monthlyPlan = plans.find(
      (p) => p.type === selectedPlan.type && selectedPlan.name === p.name && p.cycle_months === 1,
    );

    const totalMonthly = monthlyPlan.price * selectedPlan.cycle_months;

    const discount = plan.price - totalMonthly;
    const discountPercentage = discount / selectedPlan.price;

    return {
      totalPrice: selectedPlan.price,
      totalMonthly,
      discount,
      discountPercentage,
    };
  }, [plans, plan, planId]);

  const renderPaymentButton = useCallback(() => {
    switch (selectedPaymentMethod) {
      case 'CREDIT_CARD':
        return (
          <>
            <Button
              isLoading={isLoading}
              disabled={isLoading}
              variant="success-2"
              className="mt-3 d-flex justify-content-center align-items-center"
              onClick={handleSubmitCheckout}
            >
              <LuCreditCard className="mr-2" />
              Pagar com cartão
              <LuArrowRight className="ml-2" />
            </Button>
            <p className="pt-3">
              Você será redirecionado para preencher seus dados do cartão.
            </p>
          </>
        );
      case 'PIX':
        return (
          <Button
            isLoading={isLoading}
            disabled={isLoading}
            variant="success-2"
            className="mt-3 d-flex justify-content-center align-items-center"
            onClick={handleSubmitCheckout}
          >
            <LuComponent className="mr-2" />
            Gerar QR Code
          </Button>
        );
      case 'BOLETO':
        return (
          <Button
            isLoading={isLoading}
            disabled={isLoading}
            variant="success-2"
            className="mt-3 d-flex justify-content-center align-items-center"
            onClick={handleSubmitCheckout}
          >
            <FaBarcode className="mr-2" />
            Gerar boleto
          </Button>
        );
      default:
        return null;
    }
  }, [selectedPaymentMethod, handleSubmitCheckout, isLoading]);

  const paymentOptions = useMemo(() => [
    {
      id: 'CREDIT_CARD',
      title: 'Pagar com cartão',
      icon: <LuCreditCard size="1.4em" className="mr-3" />,
    },
    {
      id: 'PIX',
      title: 'Pagar com Pix',
      icon: <LuComponent size="1.4em" className="mr-3" />,
    },
    {
      id: 'BOLETO',
      title: 'Pagar com Boleto',
      icon: <FaBarcode size="1.4em" className="mr-3" />,
    },
  ], []);

  return (
    <>
      <BillingInformationModal
        isVisible={isAddressModalOpen}
        onModalToggle={() => setIsAddressModalOpen(!isAddressModalOpen)}
      />
      <div className="mt-3 d-flex align-items-center">
        <Button
          variant="link"
          className="m-0 p-0 mr-3 d-flex justify-content-center align-items-center"
          onClick={onBackToPlans}
        >
          <IoArrowBack className="mr-1" />
          Voltar
        </Button>
      </div>
      <Row className="mt-3">
        <Col sm={6}>
          <h4 className="m-0 mb-3">
            Pagamento do plano {plan.name} {CYCLE_MONTHS[plan.cycle_months]}
          </h4>
          {paymentOptions.map((option) => (
            <Card key={option.id} className="mb-3" style={{ borderRadius: '8px' }}>
              <Card.Header className="m-0 p-0" style={{ borderRadius: '8px' }}>
                <ButtonToggle
                  variant="default"
                  className="d-flex flex-row justify-content-start align-items-center"
                  onClick={() => setSelectedPaymentMethod(option.id)}
                >
                  <div className="d-flex">
                    {option.icon}
                    <h6 className="m-0">
                      {option.title}
                    </h6>
                  </div>
                  <Form.Check
                    className="ml-auto"
                    type="radio"
                    checked={selectedPaymentMethod === option.id}
                  />
                </ButtonToggle>
              </Card.Header>
            </Card>
          ))}
          <Button
            variant="inverse-dark d-flex justify-content-center align-items-center"
            onClick={() => setIsAddressModalOpen(true)}
          >
            <LuPencil className="mr-2" />
            Dados de cobrança
          </Button>
        </Col>
        <Col sm={6}>
          <div>
            <h4>
              Resumo
            </h4>
            <Details className="mt-4">
              <li>
                <span>Assinatura {CYCLE_MONTHS[plan.cycle_months]} (plano {plan.name})</span>
                <span>{FORMATTERS.NUMBER(totals.totalMonthly)}</span>
              </li>
              {hasDiscount && (
                <li>
                  <span>Desconto</span>
                  <span className="text-success">
                    {FORMATTERS.NUMBER(totals.discount)}
                  </span>
                </li>
              )}
            </Details>
            <Details>
              <li style={{ borderTop: '1px solid #dee2e6', paddingTop: '0.5rem' }}>
                <span>Total</span>
                <span><strong>{FORMATTERS.NUMBER(plan.price)}</strong></span>
              </li>
            </Details>
            {renderPaymentButton()}
          </div>
        </Col>
      </Row>
    </>
  );
}

SelectPaymentMethod.propTypes = {
  planId: PropTypes.string.isRequired,
  plans: PropTypes.array.isRequired,
  onCreateCheckout: PropTypes.func.isRequired,
  onBackToPlans: PropTypes.func.isRequired,
};

export default SelectPaymentMethod;
