import styled, { css } from 'styled-components';
import DropdownItem from 'react-bootstrap/DropdownItem';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import Modal from '_components/_core/Modal';

import { Button, Card } from '_components/_core';

export const StyledModal = styled(Modal)`
  padding: 0 !important;

  div.modal-body {
    padding: 24px !important;
    max-height: 60vh;
    overflow: auto;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Option = styled(DropdownItem)`
  font-size: 14px;
  font-weight: 500;
  padding: 0;

  :hover, :focus {
    color: #00784e;
    background-color: transparent;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 0.8rem;
  vertical-align: top;
  font-weight: 600;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: 0.5rem;

  :hover, :focus {
    text-decoration: none;
    color: var(--text-primary);
    background-color: transparent;
  }

`;

export const CompanyAvatar = styled.img`
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 6px;
  margin-right: 8px;

  ${({ size }) =>
    size === 'small' &&
    css`
    width: 20px;
    height: 20px;
  `}
`;

export const Grid = styled.div`
  margin-left: -1.6rem;
  display: grid;
  grid-template-columns: 200px auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const LeftPanel = styled.div`
  margin-top: -1em;
  padding-top: 1em;
`;

export const CustomTabs = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0px;
  list-style: none;
  font-weight: 600;
  font-size: 14.95px;

  li {
    display: flex;
    align-items: stretch;
    padding-top: 0;
    padding-bottom: 0;

    padding: .2rem 0;
    margin: 0 1rem;

    padding-top: 1.25rem !important;
    padding-bottom: 0.5rem !important;
    border-bottom-width: 2px;
    border-bottom: 2px solid transparent;
    transition: color .2s ease;

    &:hover {
      cursor: pointer;
      color: #00784e;
    }

    &.active {
      color: #00784e;
      border-bottom: 2px solid #00784e !important;
    }
  }

  ${({ variant }) =>
    variant === 'vertical' &&
    css`
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    padding: 0 !important;
    margin: 0 !important;

    li {
      padding: 0 !important;
      margin: 0 !important;
      padding: 0.5em 1em !important;
      margin-bottom: 0.5em !important;
      border-bottom: none !important;
      border-right: 2px solid transparent !important;
      display: flex;
      align-items: center;
      

      &:hover {
        background-color: #EDF2F7;
        cursor: pointer;
      }

      &.active {
        background-color: #EDF2F7;
        border-bottom: none !important;
        color: #00784e;
        border-right: 2px solid #00784e !important;
      }

    }
  `}
 
`;

export const CustomTab = styled.li`
  
  
`;

export const CustomBreadcrumb = styled(Breadcrumb)`
  font-weight: 500;

  ${({ vertical }) =>
    vertical &&
    css`
    /* display: flex;
    flex-direction: column !important;
    align-items: flex-start !important; */
    

    ol {
      width: 100%;
      /* display: grid !important;
      grid-template-columns: 1fr 1fr !important;
      grid-gap: 4px !important; */
    }

    .breadcrumb {
      flex-direction: column !important;
      align-items: flex-start !important;
      padding: 0 !important;
      border: none !important;
      margin-bottom: 0 !important;

      .breadcrumb-item {
        padding-left: 0 !important;
        
      }

      & li {
        padding-bottom: 4px !important;

        ::before { 
          content: none !important;
        }
      }
    }
  `}
`;

export const CustomBreadcrumbItem = styled(Breadcrumb.Item)`
  font-weight: 500 !important;
`;

export const CustomCard = styled(Card)`
  border: 1px solid #F1F1F4;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding-bottom: 0;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
`;

export const CustomCardHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid #F1F1F4;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 16px;
    margin-bottom: 0;
    color: #071437;
  }

  ${({ isClickable }) =>
    isClickable &&
    css`
    cursor: pointer;

    :hover {
      background-color: #F9F9FB;
    }
  `}
`;

export const CustomCardBody = styled.div`
  padding: 16px;

  ${({ noPadding }) =>
    noPadding &&
    css`
    padding: 0;
  `}

`;

export const CustomCardFooter = styled.div`
  padding: 8px 16px;
  border-top: 1px solid #F1F1F4;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.div`
  font-weight: 600;
  color: #99A1B7;
  font-size: 13px;

  span.dark {
    color: #252F4A;
  }
`;

export const Value = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #252F4A;
  width: fit-content;

  ${({ variant }) =>
    variant === 'large' &&
    css`
    font-size: 24px;

    small {
      font-size: 14px;
    }
  `}
  
  ${({ variant }) =>
    variant === 'medium' &&
    css`
    font-size: 18px;

    small {
      font-size: 10px;
    }
  `}

  ${({ variant }) =>
    variant === 'light' &&
    css`
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #78829D;
  `}
`;
