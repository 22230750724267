import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import {
  SubscriptionAmountCard,
  SubscriptionRenewalDateCard,
  SubscriptionItemsCard,
  SubscriptionInformationCard,
} from './components';

function Overview({ subscription, onChangeView }) {
  if (!subscription) {
    return null;
  }

  return (
    <>
      <Row>
        <Col md={8}>
          <Row>
            <Col md={6}>
              <SubscriptionAmountCard subscription={subscription} />
            </Col>
            <Col md={6}>
              <SubscriptionRenewalDateCard subscription={subscription} />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <SubscriptionItemsCard subscription={subscription} onChangeView={onChangeView} />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <SubscriptionInformationCard subscription={subscription} onChangeView={onChangeView} />
        </Col>
      </Row>
    </>
  );
}

Overview.defaultProps = {
  subscription: null,
};

Overview.propTypes = {
  onChangeView: PropTypes.func,
  subscription: PropTypes.object,
};

export default Overview;
