import { connect } from 'react-redux';

import companyActionCreators from '_store/_actions/company';

import CompanyAvatarUpload from './CompanyAvatarUpload';

const mapDispatchToProps = {
  onCompanyAvatarUpload: companyActionCreators.addCompanyAvatar,
  onCompanyAvatarDelete: companyActionCreators.deleteCompanyAvatar,
};

export default connect(null, mapDispatchToProps)(CompanyAvatarUpload);
