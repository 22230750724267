/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FORMATTERS from 'helpers/formatters';

import { Button } from '_components/_core';
import { SubscriptionStatusTag } from '_components/_shared';
import BillingInformationModal from '../../BillingInformationModal/BillingInformationModalContainer';

import { CustomCard, CustomCardBody, Value } from '../../../../../styles';

function SubscriptionInformationCard({ subscription, onChangeView }) {
  const [isBillingInfoModalOpen, setIsBillingInfoModalOpen] = useState(false);

  const InfoItem = ({ title, content, onClick }) => (
    <>
      <Value className="w-100 d-flex justify-content-between align-items-center">
        {title}
        {onClick && (
          <Button
            variant="link"
            size="small"
            className="m-0 p-0 ml-auto d-flex justify-content-center align-items-center"
            onClick={onClick}
          >
            Editar
          </Button>
        )}
      </Value>
      <Value variant="light" className="mt-1">
        {content}
      </Value>
    </>
  );

  const renderSubscriptionAction = () => {
    const { status } = subscription;

    if (status === 'active') {
      return (
        <Button
          variant="link"
          className="mt-3 w-100"
          onClick={() => onChangeView('CANCEL_SUBSCRIPTION')}
        >
          <span className="text-danger">Cancelar assinatura</span>
        </Button>
      );
    }

    if (status === 'canceled') {
      return (
        <Button
          variant="success-2"
          className="mt-3 pt-3 pb-3 w-100"
          onClick={() => onChangeView('CHECKOUT')}
        >
          <span>Reativar assinatura</span>
        </Button>
      );
    }

    return null;
  };

  return (
    <CustomCard>
      <BillingInformationModal
        subscription={subscription}
        isVisible={isBillingInfoModalOpen}
        onModalToggle={() => setIsBillingInfoModalOpen(!isBillingInfoModalOpen)}
      />
      <CustomCardBody>
        <InfoItem
          title="Situação"
          content={<SubscriptionStatusTag status={subscription.status} />}
        />
        <hr />
        <InfoItem
          title="Dados de cobrança"
          content={
            <>
              <span>{subscription.billing_info.name}</span> <br />
              <span>
                {FORMATTERS.CPF_CNPJ(subscription.billing_info.document_number)}
              </span>{' '}
              <br />
              <span className="mt-1">
                {subscription.billing_info.address_street},{' '}
                {subscription.billing_info.address_number} -{' '}
                {subscription.billing_info.address_complement}
              </span>{' '}
              <br />
              <span>
                {subscription.billing_info.address_zip_code} -{' '}
                {subscription.billing_info.address_city} -{' '}
                {subscription.billing_info.address_state}
              </span>{' '}
              <br />
            </>
          }
          onClick={() => setIsBillingInfoModalOpen(true)}
        />
        <hr />
        <InfoItem
          title="E-mails de cobrança"
          content={
            <>
              <span>{subscription.billing_info.email_primary}</span> <br />
              <span>{subscription.billing_info.email_secondary}</span>
            </>
          }
          onClick={() => setIsBillingInfoModalOpen(true)}
        />
        <hr />
        <InfoItem
          title="Forma de pagamento"
          content="TODO"
          onClick={() => setIsBillingInfoModalOpen(true)}
        />
        <hr />
        <InfoItem
          title="Outras informações"
          content={
            <>
              <span>{`Assinado em: ${FORMATTERS.DATE_DDMMYYYY(subscription.dates.created_at)}`}</span>{' '}
              <br />
              {subscription.dates.canceled_at && (
                <span>
                  Cancelado em: {FORMATTERS.DATE_DDMMYYYY(subscription.dates.canceled_at)}
                </span>
              )}
            </>
          }
        />
        {renderSubscriptionAction()}
      </CustomCardBody>
    </CustomCard>
  );
}

SubscriptionInformationCard.defaultProps = {
  subscription: null,
};

SubscriptionInformationCard.propTypes = {
  subscription: PropTypes.object,
  onChangeView: PropTypes.func.isRequired,
};

export default SubscriptionInformationCard;
