import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';
import alertActionCreators from '../alert.actions';
import getActiveCompany from './getActiveCompany';
import getAllCompanies from './getAllCompanies';

const updateFinancialCompany = (id, company, callback) => {
  return dispatch => {
    dispatch(request(company));

    service
      .updateFinancialCompany(id, company)
      .then(response => {
        const updatedCompany = response.data;

        dispatch(success(updatedCompany));
        dispatch(alertActionCreators.success('Empresa atualizada com sucesso!'));
        dispatch(getActiveCompany());
        dispatch(getAllCompanies());

        if (callback) {
          service.getById(id).then(response => {
            callback(response.data);
          });
        }
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          const { message } = response.data;

          dispatch(failure(message));
        }
      });
  };

  function request(company) {
    return {
      type: constants.UPDATE_FINANCIAL_COMPANY_REQUEST,
      payload: { company },
    };
  }
  function success(company) {
    return {
      type: constants.UPDATE_FINANCIAL_COMPANY_SUCCESS,
      payload: company,
    };
  }
  function failure(message) {
    return {
      type: constants.UPDATE_FINANCIAL_COMPANY_FAILURE,
      payload: { message },
    };
  }
};

export default updateFinancialCompany;
