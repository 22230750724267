import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Col, Row } from 'react-bootstrap';
import { IoArrowBack } from 'react-icons/io5';

import { Button } from '_components/_core';

import Plan from './Plan';

import { PlansContainer, StyledCycleButton } from '../styles';

function SelectPlan({
  plans,
  subscription,
  onChoosePlan,
  onBackToHome,
}) {
  const [billingCycle, setBillingCycle] = useState(1);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(null);

  const validPlans = plans.filter(
    (plan) => plan.type === 'V1_PLAN' && plan.cycle_months === billingCycle,
  );

  const renderBackButton = useCallback(() => {
    if (!subscription || subscription.status === 'trialing') {
      return null;
    }

    return (
      <Button
        variant="link"
        className="m-0 p-0 mr-3 d-flex justify-content-center align-items-center"
        onClick={onBackToHome}
      >
        <IoArrowBack className="mr-1" />
        Voltar
      </Button>
    );
  }, [subscription, onBackToHome]);

  return (
    <>
      <div className="mt-3">
        <div className="mt-3 d-flex align-items-center">
          {renderBackButton()}
        </div>
        <h4 className="m-0 d-flex justify-content-center align-items-center">
          Assine um plano para manter sua empresa organizada
        </h4>
      </div>
      <div className="mt-3 mb-3 d-flex justify-content-center align-items-center">
        <ButtonGroup className="ml-auto mr-auto">
          <StyledCycleButton
            variant="default"
            onClick={() => setBillingCycle(1)}
            active={billingCycle === 1}
          >
            Mensal
          </StyledCycleButton>
          <StyledCycleButton
            variant="default"
            onClick={() => setBillingCycle(6)}
            active={billingCycle === 6}
          >
            Semestral
          </StyledCycleButton>
          <StyledCycleButton
            variant="default"
            onClick={() => setBillingCycle(12)}
            active={billingCycle === 12}
          >
            Anual
          </StyledCycleButton>
        </ButtonGroup>
      </div>
      <Row className="d-flex align-items-center">
        <Col md={{ span: 12, offset: 0 }}>
          <PlansContainer>
            {validPlans.map((plan, index) => (
              <Plan
                key={plan.id}
                plan={plan}
                isActive={selectedPlanIndex === index}
                onSelectPlan={() => setSelectedPlanIndex(index)}
                onChoosePlan={() => onChoosePlan(plan.id)}
              />
            ))}
          </PlansContainer>
        </Col>
      </Row>
    </>
  );
}

SelectPlan.propTypes = {
  plans: PropTypes.array.isRequired,
  onChoosePlan: PropTypes.func.isRequired,
  onBackToHome: PropTypes.func.isRequired,
  subscription: PropTypes.object,
};

export default SelectPlan;
