import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function SimpleAlert({ variant, children, className }) {
  return (
    <Container className={className} variant={variant}>
      {children}
    </Container>
  );
}

SimpleAlert.defaultProps = {
  variant: 'default',
  className: null,
};

SimpleAlert.propTypes = {
  variant: PropTypes.oneOf(['default', 'danger', 'success', 'warning', 'info']),
  children: PropTypes.node.isRequired,
};

export default SimpleAlert;
