import styled from 'styled-components';

import Modal from '_components/_core/Modal';

export const StyledTable = styled.table`
  &&& {
    font-size: 14px;

    thead tr th {
      vertical-align: middle;
      font-weight: 600;
      font-size: 13px;
      padding: .625rem 1rem;
      color: #78829d;
      background-color: #fcfcfc;
      border-bottom: 1px solid #f1f1f4;
      border-right: 1px solid #f1f1f4;
    }

    tbody tr td {
      padding: .75rem 1rem;
      vertical-align: middle;
      /* border-top: 1px solid #e7eaef; */
      font-weight: 400;
      border-bottom: 1px solid #f1f1f4;
      border-right: 1px solid #f1f1f4;
    }

    tbody tr:first-child td {
      border-top: none;
    }
  }
`;

export const StyledModal = styled(Modal)`
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default StyledTable;
