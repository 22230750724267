import styled, { css } from 'styled-components';

import { Button } from '_components/_core';
import { CustomCard } from '../../../../styles';

export const PlansContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Plan = styled(CustomCard)`
  &:hover {
    border-color: #38a915;
    cursor: pointer;
  }

  ${({ active }) => active && css`
    border-color: #38a915;
  `}
`;

export const PlanNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PlanName = styled.h4`
  color: #071437;
  text-align: left;
  
`;

export const PlanDescription = styled.small`
  color: #6c757d;
  font-size: .677rem;
  font-weight: 500;
  display: block;

`;

export const PlanPrice = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: #071437;
`;

export const PlanCycle = styled.small`
 
`;

export const PlanFeatures = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  li {
    display: flex;
    flex-direction: column;
    margin-bottom: .5rem;
    font-weight: 500;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const PaymentMethodsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const PaymentMethods = styled.div`
  
`;

export const PaymentMethod = styled(CustomCard)`
  border: 2px dotted #F1F1F4;
  box-shadow: none;

  &:hover {
    border-color: #38a915;
    cursor: pointer;
  }

  ${({ active }) => active && css`
    border-color: #38a915;
    background-color: rgba(215 255 188 / 15%);
  `}
`;

export const StyledButton = styled(Button)`
  
`;

export const StyledCycleButton = styled(Button)`
  background-color: #fff;

  &.active {
    background-color: transparent;
  }
`;

export const Details = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 0;

  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
    font-weight: 400;
    font-size: 1rem;
  }
`;

export const ButtonToggle = styled(Button)`
  width: 100%;
  background: #fff;
  border: none;
  padding-top: 14px;
  padding-bottom: 14px;
  border: 1px solid rgba(70, 77, 228, 0.05);
  color: #071437;
  border-radius: 8px;
`;
