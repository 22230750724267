import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { IoMail } from 'react-icons/io5';
import { FaDownload } from 'react-icons/fa';
import Container from 'react-bootstrap/Container';
import classNames from 'classnames';

import { PageHeader, Tabs } from '_components/_core';

import { Download, Email } from './components';

function ExportFinancialData({
  activeCompany,
  subscription,
  users,
  onDownloadFinancialData,
  onFetchUsers,
  isCompact,
}) {
  const getTabs = useCallback(() => {
    const props = {
      activeCompany,
      subscription,
      users,
      onDownloadFinancialData,
      onFetchUsers,
    };

    return [
      {
        id: 1,
        title: 'Download',
        content: <Download {...props} />,
        icon: <FaDownload className="mr-3" />,
        shortName: 'exportar-download',
      },
      {
        id: 2,
        title: 'Enviar por e-mail',
        content: <Email {...props} />,
        icon: <IoMail className="mr-3" />,
        shortName: 'exportar-email',
      },
    ];
  }, [activeCompany, subscription, users, onDownloadFinancialData, onFetchUsers]);

  return (
    <Container
      fluid
      className={classNames({
        'content-wrapper': !isCompact,
      })}
    >
      {!isCompact && <PageHeader title="Exportar Dados" variant="small" />}
      <Tabs variant="secondary" tabs={getTabs()} />
    </Container>
  );
}

ExportFinancialData.defaultProps = {
  subscription: {},
  users: [],
  isCompact: false,
};

ExportFinancialData.propTypes = {
  activeCompany: PropTypes.string,
  subscription: PropTypes.object,
  users: PropTypes.array,
  onFetchUsers: PropTypes.func,
  onDownloadFinancialData: PropTypes.func,
  isCompact: PropTypes.bool,
};

export default ExportFinancialData;
