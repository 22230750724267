import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import {
  CustomBreadcrumb,
  CustomBreadcrumbItem,
} from '../../../../styles';

function AdditionalUsers({
  activeCompany,
  onFetchPayments,
  onChangeView,
}) {
  useEffect(() => {
    onFetchPayments();
  }, [onFetchPayments, activeCompany]);

  return (
    <>
      <CustomBreadcrumb>
        <CustomBreadcrumbItem
          onClick={() => onChangeView('DEFAULT')}
        >Assinatura
        </CustomBreadcrumbItem>
        <CustomBreadcrumbItem active>
          Usuários adicionais
        </CustomBreadcrumbItem>
      </CustomBreadcrumb>
      <Row>
        <Col>
          TODO: Usuários adicionais
        </Col>
      </Row>
    </>
  );
}

AdditionalUsers.defaultProps = {
  activeCompany: {},
};

AdditionalUsers.propTypes = {
  onFetchPayments: PropTypes.func,
  activeCompany: PropTypes.object,
  onChangeView: PropTypes.func,
};

export default AdditionalUsers;
