import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Form,
} from 'react-bootstrap';
import { Formik } from 'formik';

import FORMATTERS from 'helpers/formatters';
import {
  Button,
  FormCpfCnpjField,
  FormSelectField,
  FormTextField,
} from '_components/_core';
import { AddressFormFields } from '_components/_shared';
import { BillingInformationSchema } from './utilities';

import { StyledModal, ModalFooter } from './styles';

function BillingInformation({
  isVisible,
  onModalToggle,
  subscription,
  onUpdateBillingInformation,
  onFetchSubscription,
  onFetchCep,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const { billing_info } = subscription;

  const handleSubmitForm = (values, { setErrors }) => {
    setIsLoading(true);

    const successCallback = (address) => {
      const { erro } = address;

      if (erro) {
        setIsLoading(false);

        setErrors({ address_zip_code: 'CEP inválido' });

        return;
      }

      onUpdateBillingInformation({
        ...values,
        address_zip_code: FORMATTERS.CLEAN_CEP(values.address_zip_code),
        document_number: FORMATTERS.CLEAN_CPF_CNPJ(values.document_number),
      }, () => {
        onFetchSubscription();
        onModalToggle();

        setIsLoading(false);
      });
    };

    const errorCallback = () => {
      setIsLoading(false);

      setErrors({ address_zip_code: 'CEP inválido' });
    };

    onFetchCep(values.address_zip_code, successCallback, errorCallback);
  };

  const renderModalFooter = useCallback((handleSubmit, isValid) => (
    <ModalFooter>
      <Button
        variant="secondary"
        onClick={() => onModalToggle()}
      >
        Cancelar
      </Button>
      <Button
        type="submit"
        variant="dark"
        onClick={handleSubmit}
        isLoading={isLoading}
        disabled={!isValid || isLoading}
      >
        Salvar alterações
      </Button>
    </ModalFooter>
  ), [onModalToggle, isLoading]);

  if (!subscription) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        name: billing_info.name,
        document_type: billing_info.document_type,
        document_number: billing_info.document_number,
        phone_number: billing_info.phone_number,
        email_primary: billing_info.email_primary,
        email_secondary: billing_info.email_secondary,
        address_zip_code: billing_info.address_zip_code,
        address_street: billing_info.address_street,
        address_number: billing_info.address_number,
        address_district: billing_info.address_district,
        address_complement: billing_info.address_complement,
        address_city: billing_info.address_city,
        address_city_ibge: billing_info.address_city_ibge,
        address_state: billing_info.address_state,
        address_state_ibge: billing_info.address_state_ibge,
        address_country: 'BR',
      }}
      validationSchema={BillingInformationSchema}
      onSubmit={handleSubmitForm}
      enableReinitialize
    >
      {({
        handleSubmit,
        isValid,
        values,
        setFieldValue,
        setErrors,
      }) => (
        <>
          <StyledModal
            title="Alterar dados de cobrança"
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(handleSubmit, isValid)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="4">
                  <Form.Label>Tipo de documento</Form.Label>
                  <FormSelectField
                    name="document_type"
                    options={[
                      { value: 'CPF', label: 'CPF' },
                      { value: 'CNPJ', label: 'CNPJ' },
                    ]}
                    onChange={(option) => {
                      setFieldValue('document_type', option.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Label>Número do documento</Form.Label>
                  <FormCpfCnpjField
                    name="document_number"
                    type={values.document_type}
                    placeholder="Digite o número do documento"
                  />
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Label>{values.document_type === 'CPF' ? 'Nome completo' : 'Razão social'}</Form.Label>
                  <FormTextField
                    name="name"
                    placeholder={values.document_type === 'CPF' ? 'Nome completo' : 'Razão social'}
                  />
                </Form.Group>
              </Form.Row>
              <hr />
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>E-mail de cobrança principal</Form.Label>
                  <FormTextField
                    name="email_primary"
                    placeholder="E-mail principal"
                    type="email"
                    disabled
                  />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>E-mail de cobrança secundário</Form.Label>
                  <FormTextField
                    name="email_secondary"
                    placeholder="E-mail secundário"
                    type="email"
                  />
                </Form.Group>
              </Form.Row>
              <hr />
              <AddressFormFields
                onCepError={() => {
                  setErrors({ address_zip_code: 'CEP inválido' });
                }}
                columnSizes={{
                  cep: 4,
                  searchButton: 8,
                  street: 4,
                  number: 4,
                  district: 4,
                  complement: 4,
                  city: 4,
                  state: 4,
                }}
              />
            </Form>
          </StyledModal>
        </>
      )}
    </Formik>
  );
}

BillingInformation.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  subscription: PropTypes.object,
  onModalToggle: PropTypes.func.isRequired,
  onUpdateBillingInformation: PropTypes.func.isRequired,
  onFetchSubscription: PropTypes.func.isRequired,
  onFetchCep: PropTypes.func.isRequired,
};

export default BillingInformation;
