import constants from '../../_constants/integrations.constants';
import service from '../../../services/integrations.service';
import alertActionCreators from '../alert.actions';

const createIntegration = (data, callback) => {
  return (dispatch) => {
    dispatch(request(data));

    service.createIntegration(data).then(
      (response) => {
        dispatch(success(response));

        dispatch(alertActionCreators.success('Integração criada com sucesso!'));

        if (callback) {
          callback(response.data);
        }
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request(data) {
    return {
      type: constants.CREATE_INTEGRATION_REQUEST,
      payload: { data },
    };
  }
  function success(response) {
    return {
      type: constants.CREATE_INTEGRATION_SUCCESS,
      payload: { response },
    };
  }
  function failure(error) {
    return {
      type: constants.CREATE_INTEGRATION_FAILURE,
      payload: error,
    };
  }
};

export default createIntegration;
