import { connect } from 'react-redux';
import { compose } from 'redux';

import apiKeyActionCreators from '_store/_actions/apiKeys';
import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';
import integrationsActionsCreators from '_store/_actions/integrations';

import ApiKey from './ApiKey';

const mapStateToProps = (state) => ({
  apiKeys: state.apiKeys.apiKeys,
  isLoading: state.apiKeys.isLoading,
  isLoadingIntegration: state.integrations.isLoading,
  bankIntegrationSettings: state.integrations.bankIntegrationSettings,
});

const mapDispatchToProps = {
  onFetchKeys: apiKeyActionCreators.getAllKeys,
  onDeleteKey: apiKeyActionCreators.deleteKey,
  onCreateIntegration: integrationsActionsCreators.createIntegration,
  onFetchIntegrationByType: integrationsActionsCreators.getIntegrationByType,
  onFetchIntegrations: integrationsActionsCreators.getAllIntegrations,
  onDeleteProvider: integrationsActionsCreators.deleteIntegration,
  onFetchIntegration: integrationsActionsCreators.getIntegrationByType,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermissions(['access_api'], 'all', true, 'component'),
)(ApiKey);
