export const subscriptionConstants = {
  FETCH_SUBSCRIPTION_PLANS_REQUEST: 'FETCH_SUBSCRIPTION_PLANS_REQUEST',
  FETCH_SUBSCRIPTION_PLANS_SUCCESS: 'FETCH_SUBSCRIPTION_PLANS_SUCCESS',
  FETCH_SUBSCRIPTION_PLANS_FAILURE: 'FETCH_SUBSCRIPTION_PLANS_FAILURE',
  CREATE_CHECKOUT_SESSION_REQUEST: 'CREATE_CHECKOUT_SESSION_REQUEST',
  CREATE_CHECKOUT_SESSION_SUCCESS: 'CREATE_CHECKOUT_SESSION_SUCCESS',
  CREATE_CHECKOUT_SESSION_FAILURE: 'CREATE_CHECKOUT_SESSION_FAILURE',
  FETCH_SUBSCRIPTION_STATUS_REQUEST: 'FETCH_SUBSCRIPTION_STATUS_REQUEST',
  FETCH_SUBSCRIPTION_STATUS_SUCCESS: 'FETCH_SUBSCRIPTION_STATUS_SUCCESS',
  FETCH_SUBSCRIPTION_STATUS_FAILURE: 'FETCH_SUBSCRIPTION_STATUS_FAILURE',
  CREATE_AUTHORIZED_CHECKOUT_SESSION_REQUEST:
    'CREATE_AUTHORIZED_CHECKOUT_SESSION_REQUEST',
  CREATE_AUTHORIZED_CHECKOUT_SESSION_SUCCESS:
    'CREATE_AUTHORIZED_CHECKOUT_SESSION_SUCCESS',
  CREATE_AUTHORIZED_CHECKOUT_SESSION_FAILURE:
    'CREATE_AUTHORIZED_CHECKOUT_SESSION_FAILURE',
  UPDATE_PLAN_REQUEST: 'UPDATE_PLAN_REQUEST',
  UPDATE_PLAN_SUCCESS: 'UPDATE_PLAN_SUCCESS',
  UPDATE_PLAN_FAILURE: 'UPDATE_PLAN_FAILURE',
  CREATE_UPDATE_PAYMENT_SESSION_REQUEST: 'CREATE_UPDATE_PAYMENT_SESSION_REQUEST',
  CREATE_UPDATE_PAYMENT_SESSION_SUCCESS: 'CREATE_UPDATE_PAYMENT_SESSION_SUCCESS',
  CREATE_UPDATE_PAYMENT_SESSION_FAILURE: 'CREATE_UPDATE_PAYMENT_SESSION_FAILURE',

  FETCH_COMPANY_SUBSCRIPTION_REQUEST: 'FETCH_COMPANY_SUBSCRIPTION_REQUEST',
  FETCH_COMPANY_SUBSCRIPTION_SUCCESS: 'FETCH_COMPANY_SUBSCRIPTION_SUCCESS',
  FETCH_COMPANY_SUBSCRIPTION_FAILURE: 'FETCH_COMPANY_SUBSCRIPTION_FAILURE',

  FETCH_COMPANY_SUBSCRIPTION_PLANS_REQUEST: 'FETCH_COMPANY_SUBSCRIPTION_PLANS_REQUEST',
  FETCH_COMPANY_SUBSCRIPTION_PLANS_SUCCESS: 'FETCH_COMPANY_SUBSCRIPTION_PLANS_SUCCESS',
  FETCH_COMPANY_SUBSCRIPTION_PLANS_FAILURE: 'FETCH_COMPANY_SUBSCRIPTION_PLANS_FAILURE',

  UPDATE_BILLING_INFORMATION_REQUEST: 'UPDATE_BILLING_INFORMATION_REQUEST',
  UPDATE_BILLING_INFORMATION_SUCCESS: 'UPDATE_BILLING_INFORMATION_SUCCESS',
  UPDATE_BILLING_INFORMATION_FAILURE: 'UPDATE_BILLING_INFORMATION_FAILURE',

  CREATE_CHECKOUT_REQUEST: 'CREATE_CHECKOUT_REQUEST',
  CREATE_CHECKOUT_SUCCESS: 'CREATE_CHECKOUT_SUCCESS',
  CREATE_CHECKOUT_FAILURE: 'CREATE_CHECKOUT_FAILURE',

  CANCEL_SUBSCRIPTION_REQUEST: 'CANCEL_SUBSCRIPTION_REQUEST',
  CANCEL_SUBSCRIPTION_SUCCESS: 'CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_FAILURE: 'CANCEL_SUBSCRIPTION_FAILURE',
};

export default subscriptionConstants;
