import { connect } from 'react-redux';
import { compose } from 'redux';

import companyActionCreators from '_store/_actions/company';

import SettingsV2 from './SettingsV2';

const mapStateToProps = state => ({
  activeCompany: state.company.activeCompany,
  user: state.auth.user,
});

const mapDispatchToProps = {
  onFetchMarketSegments: companyActionCreators.fetchMarketSegments,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SettingsV2);
