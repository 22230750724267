/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { LuCheckCircle2, LuReceipt, LuUserPlus2 } from 'react-icons/lu';
import isEmpty from 'lodash/isEmpty';

import FORMATTERS from 'helpers/formatters';
import { useMediaQuery } from 'helpers';
import { Button, FastFilterSelect } from '_components/_core';

import {
  CustomCard,
  CustomCardBody,
  CustomCardHeader,
} from '../../../../../styles';
import {
  PlanDescription,
  PlanFeatures,
  PlanName,
} from '../styles';

function SubscriptionItemsCard({ subscription, onChangeView }) {
  const { isMobile } = useMediaQuery();

  const {
    max_users,
    max_custom_receipts,
    max_storage_size_megabytes,
  } = subscription.plan.features;

  const FeatureItem = ({ feature }) => (
    <li>
      <div className="d-flex align-items-center">
        <LuCheckCircle2 size="1.1rem" />
        <span className="ml-2">{feature}</span>
      </div>
    </li>
  );

  const features = [
    'Fluxo de Caixa Completo',
    `${max_users} Usuários`,
    `${max_custom_receipts} Recibos Personalizados`,
    `${max_storage_size_megabytes / 1000} GB de armazenamento`,
  ];

  const additional_items = [
    {
      name: `${1} ${'usuário'} ${'adicional'}`,
      price: 5,
    },
  ];

  return (
    <CustomCard>
      <CustomCardHeader>
        <div className="d-flex justify-content-center align-items-center">
          <div className="d-flex flex-column justify-content-center align-items-start">
            <PlanName>
              {subscription.plan.name}
            </PlanName>
            <PlanDescription>
              {subscription.plan.description}
            </PlanDescription>
          </div>
        </div>
        <div>
          {isMobile && (
          <FastFilterSelect
            label="Ações"
            options={[
              { label: 'Comparar planos', value: 'SWITCH_PLANS' },
              { label: 'Fazer upgrade', value: 'UPGRADE' },
            ]}
            onChange={() => {}}
          />
          )}
          {!isMobile && (
          <>
            {/* <Button size="sm" variant="default">
              Comparar planos
            </Button>
            <Button size="sm" variant="success-2" className="ml-2">
              Fazer Upgrade
            </Button> */}
          </>
          )}
        </div>
      </CustomCardHeader>
      <CustomCardBody>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <PlanFeatures>
              {features.map((feature, index) => (
                <FeatureItem key={index} feature={feature} />
              ))}
            </PlanFeatures>
          </div>
          <div>
            {/* <Value variant="medium">
              {FORMATTERS.NUMBER(subscription.plan.price)}
            </Value> */}
          </div>
        </div>
        {!isEmpty(additional_items) && (
          <>
            <hr />
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <PlanFeatures>
                  {additional_items.map((item, index) => (
                    <FeatureItem key={index} feature={`${item.name} (${FORMATTERS.NUMBER(item.price)} cada)`} />
                  ))}
                </PlanFeatures>
              </div>
              <div>
                {/* <Value variant="medium">
                  {FORMATTERS.NUMBER(20)}
                </Value> */}
              </div>
            </div>
          </>
        )}
        <hr />
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <Button size="sm" variant="link" className="d-flex justify-content-between align-items-center m-b p-0">
              <LuUserPlus2 className="mr-2" /> Usuários adicionais
            </Button>&nbsp;|&nbsp;
            <Button
              size="sm"
              variant="link"
              className="d-flex justify-content-between align-items-center m-b p-0"
              onClick={() => onChangeView('PAYMENTS_HISTORY')}
            >
              <LuReceipt className="mr-2" />
              Histórico de pagamentos
            </Button>
          </div>
          <div>
            {/* <Value variant="large" className="d-flex justify-content-center align-items-center">
              <small className="mr-2">
                (a cada {subscription.plan.cycle_months} meses)
              </small>
              {FORMATTERS.NUMBER(229.90)}
            </Value> */}
          </div>
        </div>
      </CustomCardBody>
    </CustomCard>
  );
}

SubscriptionItemsCard.defaultProps = {
  subscription: null,
};

SubscriptionItemsCard.propTypes = {
  subscription: PropTypes.object,
  onChangeView: PropTypes.func.isRequired,
};

export default SubscriptionItemsCard;
