import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import { FormSelectField } from '_components/_core';

function FormMarketSegmentField({ segments }) {
  const formattedSegments = useMemo(
    () =>
      segments.map(segment => ({
        value: segment.id,
        label: segment.description,
      })),
    [segments],
  );

  return (
    <>
      <Form.Group>
        <Form.Label>Ramo de atuação</Form.Label>
        <FormSelectField
          name="market_segment_id"
          placeholder="Selecione..."
          options={formattedSegments}
        />
      </Form.Group>
    </>
  );
}

FormMarketSegmentField.defaultProps = {
  segments: [],
};

FormMarketSegmentField.propTypes = {
  segments: PropTypes.any,
};

export default FormMarketSegmentField;
