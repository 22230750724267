import constants from '../../_constants/apiKeys.constants';
import service from '../../../services/apiKeys.service';

const getAllKeys = () => {
  return (dispatch) => {
    dispatch(request());

    service.getAll().then(
      (response) => {
        dispatch(success(response.data));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.API_KEYS_GET_ALL_REQUEST,
      payload: { },
    };
  }

  function success(apiKeys) {
    return {
      type: constants.API_KEYS_GET_ALL_SUCCESS,
      payload: apiKeys,
    };
  }

  function failure(error) {
    return {
      type: constants.API_KEYS_GET_ALL_FAILURE,
      payload: error,
    };
  }
};

export default getAllKeys;
