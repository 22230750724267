import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';

const getCompanySubscription = () => {
  return (dispatch) => {
    dispatch(request());

    service.getCompanySubscription().then(
      (response) => {
        const { data } = response;

        dispatch(success({ data }));
      },
    ).catch((error) => {
      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.FETCH_COMPANY_SUBSCRIPTION_REQUEST,
      payload: { },
    };
  }
  function success(subscriptionStatus) {
    return {
      type: constants.FETCH_COMPANY_SUBSCRIPTION_SUCCESS,
      payload: subscriptionStatus,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_COMPANY_SUBSCRIPTION_FAILURE,
      payload: { error },
    };
  }
};

export default getCompanySubscription;
