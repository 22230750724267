import React from 'react';
import PropTypes from 'prop-types';

import FORMATTERS from 'helpers/formatters';
import { Button } from '_components/_core';
import {
  CustomCard,
  CustomCardBody,
  Label,
  Value,
} from '../../../../../styles';

function SubscriptionAmountCard({ subscription }) {
  if (!subscription) {
    return null;
  }

  const CYCLE = {
    1: 'mensal',
    6: 'semestral',
    12: 'anual',
  };

  const canChangePlan = subscription.plan.cycle_months !== 12 && subscription.status === 'active';
  const isAnnualPlan = subscription.plan.cycle_months === 12;

  const message = isAnnualPlan
    ? 'Você economizou 16% no plano anual!'
    : 'Economize 16% no plano anual!';

  return (
    <CustomCard>
      <CustomCardBody>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Label>Sua assinatura atual</Label>
            <Value variant="large" className="mt-2">
              {FORMATTERS.NUMBER(subscription.plan.price)}&nbsp;
              <small>{CYCLE[subscription.plan.cycle_months]}</small>
            </Value>
          </div>
        </div>
        {canChangePlan && (
          <Button size="sm" variant="link" className="m-0 p-0 mt-2" disabled={isAnnualPlan}>
            {message}
          </Button>
        )}
      </CustomCardBody>
    </CustomCard>
  );
}

SubscriptionAmountCard.defaultProps = {
  subscription: null,
};

SubscriptionAmountCard.propTypes = {
  subscription: PropTypes.object,
};

export default SubscriptionAmountCard;
