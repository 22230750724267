import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/integrations.constants';
import service from '../../../services/integrations.service';

import alertActionCreators from '../alert.actions';

const deleteIntegration = (provider, callback) => {
  return (dispatch) => {
    confirmDialog.warning({
      title: 'Remover Integração',
      message: 'Tem certeza que deseja excluir esta integração? Esta ação não poderá ser desfeita.',
      confirmButtonText: 'Sim, excluir',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(provider));

        service.deleteIntegration(provider).then(
          (response) => {
            dispatch(success(response.data));
            dispatch(alertActionCreators.success('Integração excluída com sucesso!'));

            if (callback) {
              callback();
            }
          },
        ).catch((error) => {
          dispatch(failure(error));
        });
      },
      onCancel: () => {},
    });
  };

  function request() {
    return {
      type: constants.DELETE_INTEGRATION_SETTINGS_REQUEST,
      payload: { },
    };
  }
  function success(apiKeys) {
    return {
      type: constants.DELETE_INTEGRATION_SETTINGS_SUCCESS,
      payload: apiKeys,
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_INTEGRATION_SETTINGS_FAILURE,
      payload: error,
    };
  }
};

export default deleteIntegration;
