import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';

const createCheckout = (params, callback, errorCallback) => {
  return (dispatch) => {
    dispatch(request());

    service.createCheckout(params).then(
      (response) => {
        const { data } = response;

        dispatch(success({ data }));

        if (callback) {
          callback(data);
        }
      },
    ).catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }

      dispatch(failure(error));
    });
  };

  function request() {
    return {
      type: constants.CREATE_CHECKOUT_REQUEST,
      payload: {},
    };
  }
  function success(data) {
    return {
      type: constants.CREATE_CHECKOUT_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.CREATE_CHECKOUT_FAILURE,
      payload: { error },
    };
  }
};

export default createCheckout;
