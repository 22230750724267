import styled from 'styled-components';

export const StyledTable = styled.table`
  &&& {
    font-size: 14px;
    width: 100%;

    thead tr th {
      vertical-align: middle;
      font-weight: 600;
      font-size: 13px;
      padding: .625rem 1rem;
      color: #78829d;
      background-color: #fcfcfc;
      border-bottom: 1px solid #f1f1f4;
      border-right: 1px solid #f1f1f4;
    }

    tbody tr td {
      padding: 1rem .75rem;
      vertical-align: middle;
      border-top: 1px solid #e7eaef;
      font-weight: 400;
    }

    tbody tr:first-child td {
      border-top: none;
    }
  }
`;

export const ActiveKeys = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 1rem;
  }

  span.key-name {
    color: #071437;
    font-weight: 600;
    font-size: 1.075rem !important;
  }

  span.expire-at {
    color: #99A1B7;
    font-weight: 500;
    font-size: .95rem !important;
  }
`;

export const KeyName = styled.span`
  color: #071437;
  font-weight: 600;
  font-size: 13.975px;
`;

export const ExpireAt = styled.span`
  color: #99A1B7;
  font-weight: 500;
  font-size: 12.35px;
`;
