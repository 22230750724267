import getAllKeys from './getAllKeys';
import deleteKey from './deleteKey';
import createKey from './createKey';
import updateKey from './updateKey.js';

export default {
  getAllKeys,
  deleteKey,
  createKey,
  updateKey,
};
