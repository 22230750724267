import { connect } from 'react-redux';
import { compose } from 'redux';

import subscriptionActionCreators from '_store/_actions/subscription';
import ibgeActionCreators from '_store/_actions/ibge';

import BillingInformationModal from './BillingInformationModal';

const mapStateToProps = (state) => ({
  payments: state.payments.payments,
  activeCompany: state.company.activeCompany,
  subscription: state.subscription.companySubscription,
});

const mapDispatchToProps = {
  onUpdateBillingInformation: subscriptionActionCreators.updateBillingInformation,
  onFetchSubscription: subscriptionActionCreators.getCompanySubscription,
  onFetchCep: ibgeActionCreators.fetchCep,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(BillingInformationModal);
