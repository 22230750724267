import HttpService from './http.service';

export default {
  createIntegration,
  updateIntegration,
  getIntegrationByType,
  getAllIntegrations,
  deleteIntegration,
};

async function createIntegration(params) {
  return HttpService.post('/payments/setup', params);
}

async function updateIntegration(provider, params) {
  return HttpService.put(`/payments/setup/${provider}`, params);
}

async function getIntegrationByType(params) {
  return HttpService.get('/payments/setup', params);
}

async function getAllIntegrations() {
  return HttpService.get('/payments/setup');
}

async function deleteIntegration(provider) {
  return HttpService.delete(`/payments/setup/${provider}`);
}
