import { connect } from 'react-redux';

import companyActionCreators from '_store/_actions/company';
import companySelectors from '_store/_selectors/company';

import CompanySwitcher from './CompanySwitcher';

const mapStateToProps = state => ({
  activeCompany: companySelectors.activeCompanySelector(state),
  companies: state.company.companies,
  isLoading: state.company.isLoadingActiveCompany,
  collapsed: state.sidebar.collapsed,
  companiesList: state.company.companies,
});

const mapDispatchToProps = {
  onFetchActiveCompany: companyActionCreators.getActiveCompany,
  onFetchCompanies: companyActionCreators.getAllCompanies,
  onFetchMyCompanies: companyActionCreators.fetchMyCompanies,
  onActivateCompany: companyActionCreators.setActiveCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySwitcher);
